import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../../api-authorization/AuthorizeService';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ClientViewModel from '../../ViewModels/ClientViewModel';
import InfoEdit from '../../Utilities/InfoEdit';
// import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import { CreatePackageViewModel } from '../../ViewModels/CampaignViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8,
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        },
    })
);

interface IInfoProps {
    clientId: number;
}

export default function ClientInfo(props: IInfoProps) {
    const classes = useStyles();
    const [loaded, setLoaded] = React.useState(false);
    const [client, setClient] = React.useState(new ClientViewModel());
    const [user, setUser] = React.useState<any>(null);
    const [redirectToList, setRedirect] = React.useState(false);
    const [openWarning, setOpen] = React.useState(false);
    // const [openLpwReason, setLpwReason] = React.useState(false);
    // const [submitting, setSubmitting] = React.useState(false);
    //const [lpw, setLpw] = React.useState(0);
    // const [reason, setReason] = React.useState("");
    const [countries, setCountries] = React.useState<DropdownViewModel[]>([]);
    const [packages, setPackages] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => {
        const getData = async () => {
            setLoaded(false);
            const token = await authService.getAccessToken();
            const user = await authService.getUser();
            setUser(user);

            fetch(`Client/GetClient?clientId=${props.clientId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setClient(data);
                    setLoaded(true);
                })
                .catch(error => {
                    console.log('client error: ' + error);
                    setLoaded(true);
                });
            fetch('Campaign/GetCountries', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setCountries(data);
                });
            fetch(`Campaign/GetPackages`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setPackages(data);
                })
                .catch(error => {
                    console.log('Package list error: ' + error);
                });
        }
        getData();
    }, [props.clientId]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // setLpwReason(false);
    };
    const updateContactName = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdateContactName?id=${props.clientId}&contactName=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setClient({
                    ...client,
                    contactName: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updatePartner = async (value: string | number | Date | null) => {
        try {
            debugger;
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdatePartner?id=${props.clientId}&partner=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setClient({
                    ...client,
                    partner: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updateContactPhone = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdateContactPhone?id=${props.clientId}&contactPhone=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setClient({
                    ...client,
                    contactPhone: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updateContactEmail = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdateContactEmail?id=${props.clientId}&contactEmail=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setClient({
                    ...client,
                    contactEmail: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updateStartDate = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdateStartDate?id=${props.clientId}&startDate=${(value as Date).toJSON()}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setClient({
                    ...client,
                    startDate: value as Date
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    };
    const updateAgreement = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();
            const date = value === null ? '' : `${(value as Date).toJSON()}`;
            const response = await fetch(`Client/UpdateAgreementEnd?id=${props.clientId}&date=` + date, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setClient({
                    ...client,
                    agreementEnd: value as Date | null
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }

   


    const updateRenewal = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdateRenewal?id=${props.clientId}&renewal=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            if (response.ok) {
                setClient({
                    ...client,
                    renewalDate: value as number
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    const customPricing = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();
            const customPackage = new CreatePackageViewModel();
            customPackage.campaignId = client.id;
            customPackage.custom = true;
            customPackage.packageName = "Custom " + client.packageName;
            customPackage.price = parseFloat(value as string);

            const response = await fetch(`Client/CreateCustomPackage`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                method: 'POST',
                body: JSON.stringify(customPackage)
            });

            if (response.ok) {
                setClient({
                    ...client,
                    price: parseFloat(value as string),
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    //const updateWeeklyTarget = async (value: string | number | Date | null) => {
    //    setLpw(value as number);
    //    setReason("");
    //    setLpwReason(true);
    //    return true;
    //}
    const updatePackage = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdatePackage?id=${props.clientId}&packageId=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            const data = await response.json();
            if (response.ok) {
                setClient({
                    ...client,
                    packageId: data.packageId,
                    packageName: data.packageName,
                    price: data.price
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    //const handleReasonChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //    setReason(event.target.value);
    //}
    const updateCountry = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Client/UpdateCountry?id=${props.clientId}&countryId=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });
            const data = await response.json();
            if (response.ok) {
                setClient({
                    ...client,
                    countryId: data.id,
                    country: data.name
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    //const updateWeeklyTargetWithReason = async () => {
    //    setSubmitting(true);
    //    try {
    //        const token = await authService.getAccessToken();

    //        const response = await fetch(`Client/UpdateWeeklyTarget?id=${props.clientId}&target=${lpw}&reason=${reason}`, {
    //            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
    //            method: 'POST'
    //        });
    //        if (response.ok) {
    //            setClient({
    //                ...client,
    //                weeklyTargetOpportunities: lpw
    //            });
    //            setLpwReason(false);
    //            setSubmitting(false);
    //        } else {
    //            setSubmitting(false);
    //        }
    //        return true;

    //    } catch (error) {
    //        console.log('Update error: ' + error);
    //        setSubmitting(false);
    //        return true;
    //    }
    //};
    const archiveClient = async () => {
        const token = await authService.getAccessToken();

        fetch(`Client/ArchiveClient?id=${props.clientId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    };
    return (
        <Paper className={classes.paper}>
            {redirectToList && <Redirect to="/Clients" />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit info={client.partner} label="Partner" onSave={updatePartner} /> : <Skeleton />
                        :
                        <React.Fragment>
                            {
                                client.partner != null &&
                                <React.Fragment>
                                    <Typography>{loaded ? client.partner : <Skeleton />}</Typography>
                                    <Typography variant="caption">Partner</Typography>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit info={client.contactName} label="Contact Name" onSave={updateContactName} /> : <Skeleton />

                        :
                        <React.Fragment>
                            <Typography>{loaded ? client.contactName : <Skeleton />}</Typography>
                            <Typography variant="caption">Contact Name</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit info={client.contactPhone} label="Phone" onSave={updateContactPhone} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography>{loaded ? client.contactPhone : <Skeleton />}</Typography>
                            <Typography variant="caption">Phone</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit info={client.contactEmail} label="Email" onSave={updateContactEmail} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography>{loaded ? client.contactEmail : <Skeleton />}</Typography>
                            <Typography variant="caption">Email</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit info={client.renewalDate} label="Renewal Date" onSave={updateRenewal} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">Renewal Date</Typography>
                            {loaded ? <Typography>{client.renewalDate}</Typography> : <Skeleton />}
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive") ?
                        loaded ? <InfoEdit info={client.agreementEnd} date nullable label="Agreement End" onSave={updateAgreement} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">End Date</Typography>
                            <Typography>{loaded ? client.agreementEnd ? new Date(client.agreementEnd).toLocaleDateString("en-GB") : 'No End Date' : <Skeleton />}</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit selectId={client.countryId} info={client.country} label="Country" infoList={countries} onSave={updateCountry} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">Country</Typography>
                            <Typography>{loaded ? client.country : <Skeleton />}</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive" && user?.role !== "Creator") ?
                        loaded ? <InfoEdit selectId={client.packageId} info={client.packageName} label="Package" infoList={packages} onSave={updatePackage} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">Package</Typography>
                            <Typography>{loaded ? client.packageName : <Skeleton />}</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {(user?.role !== "Executive") ?
                        loaded ? <InfoEdit decorator="£" info={client.price} label="Package Price" onSave={customPricing} /> : <Skeleton />
                        :
                        <React.Fragment>
                            <Typography variant="overline">Package Price</Typography>
                            <Typography>£{loaded ? client.price : <Skeleton />}</Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        (user?.role !== "Executive" && user?.role !== "Creator") ?
                            loaded ? <InfoEdit info={client.startDate} date label="Start Date" onSave={updateStartDate} /> : <Skeleton />
                            :
                            <React.Fragment>
                                <Typography>{loaded ? new Date(client.startDate).toLocaleDateString("en-GB") : <Skeleton />}</Typography>
                                <Typography variant="caption">Start Date</Typography>
                            </React.Fragment>
                    }
                </Grid>
                {/*<Grid item xs={12}>
                    {
                        (user?.role !== "Executive" && user?.role !== "Creator") ?
                            loaded ? <InfoEdit info={client.weeklyTargetOpportunities} label="LPW" onSave={updateWeeklyTarget} /> : <Skeleton />
                            :
                            <React.Fragment>
                                <Typography>{loaded ? client.weeklyTargetOpportunities : <Skeleton />}</Typography>
                                <Typography variant="caption">LPW</Typography>
                            </React.Fragment>
                    }
                </Grid>*/}
                {(user?.role !== "Executive" && user?.role !== "Creator") &&
                    <Grid item xs={12}>
                        <Button fullWidth variant="outlined" color="secondary" onClick={handleClickOpen} disabled={!loaded}>Archive client</Button>
                    </Grid>
                }
            </Grid>
            {/*<Dialog
                open={openLpwReason}
                onClose={handleClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"LPW change reason"}</DialogTitle>
                <ValidatorForm onSubmit={updateWeeklyTargetWithReason}>
                    <DialogContent>
                        <TextValidator
                            label="Reason"
                            name="reason"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={reason}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={handleReasonChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={submitting} color="secondary">
                            Cancel
                    </Button>
                        <Button type="submit" disabled={submitting} color="primary" autoFocus>
                            Save
                    </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>*/}
            <Dialog
                open={openWarning}
                onClose={handleClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-description">
                        This will permanently archive the client.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={archiveClient} color="primary" autoFocus>
                        Archive
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}