import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import { createStyles, Theme, makeStyles } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import TeamViewModel from './TeamViewModel';
import TeamTable from './TeamTable';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//        },
//        table: {
//            minWidth: 650,
//        },
//    })
//);

export default function TeamList() {
    // const classes = useStyles();

    const [teams, setTeams] = React.useState<TeamViewModel[]>([]);
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(true);
    const [submitting, setSubmitting,] = React.useState(false);
    const [roles, setRoles] = React.useState<string[] | string>("");
    const [dialog, setDialogOpen] = React.useState(false);
    const [newTeam, setNewTeam] = React.useState<TeamViewModel>(new TeamViewModel());

    React.useEffect(() => {
        const initialise = async () => {
            setLoaded(false);
            const user = await authService.getUser();
            setRoles(user.role);
        }
        initialise();
    }, []);

    React.useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [teams, loaded]);
    const openDialog = () => {
        setDialogOpen(true);
    }
    const handleClose = (refresh: boolean) => {
        if (refresh) {
            getData();
        }
        setDialogOpen(false);
    }
    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

            fetch('Team/GetTeams', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setTeams(data);
                    fetch('Team/GetUsers', {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    })
                        .then(response => response.json())
                        .then(data => {
                            setUsers(data);
                            setLoaded(true);
                        })
                        .catch(error => { setLoaded(true) });
                })
            .catch(error => { setLoaded(true) });

       
        
    }, [roles]);
    React.useEffect(() => {
        if (roles && roles.length > 0) {
            getData();
        }
    }, [roles, getData]);

    const createTeam = async () => {
        const token = await authService.getAccessToken();

        fetch('Team/CreateTeam', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newTeam)
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    handleClose(true);
                    setNewTeam(new TeamViewModel());
                }
                setSubmitting(false);
            })
            .catch(error => { setSubmitting(false); });
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewTeam({
            ...newTeam,
            [event.target.name]: event.target.value
        });
    }

    const list = <TeamTable teams={teams} users={users} roles={roles} refresh={getData} />;

    return (
        <React.Fragment>
            {// <Paper className={classes.paper}>
                }
            <Grid container>
                <Grid item xs={12} style={{ marginTop: "5px" }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h2">Teams</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                               
                                    {(roles && (roles.includes('SuperAdmin') ||roles.includes('Admin') || roles.includes('Team Leader') || roles.includes('Creator'))) &&
                                        <Grid item>
                                        <Button fullWidth variant="contained" color="secondary" onClick={ openDialog} disabled={!loaded}>Create Team</Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {!loaded && <LinearProgress />}
                    {list}
                </Grid>
            </Grid>
            {// </Paper>
            }
        <Dialog
                open={dialog}
                onClose={handleClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <ValidatorForm onSubmit={createTeam}>
                <DialogTitle >{"Create new team"}</DialogTitle>
                <DialogContent>
                    <TextValidator
                        label="Team Name"
                        name="name"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={newTeam.name}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="secondary" disabled={submitting}>
                    Cancel
                    </Button>
                    <Button type="submit" color="primary" autoFocus disabled={submitting}>
                    Submit
                    </Button>

                    </DialogActions>
                    </ValidatorForm>
            </Dialog>
            </React.Fragment>
    );
}