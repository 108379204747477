export default class WoodpeckerViewModel {
    id: number = -1;
    name: string = '';
    status: string = '';
    folder_name: string = '';
    from_name: string = '';
    gdpr_unsubscribe: boolean = false;
    created: string = '';
    per_day: number = -1;
    from_email: string = '';
    bcc: string = '';
    cc: string = '';
    folder_id: number = -1;
    stats: Stats = new Stats();
    error: string = '';
    timestamp: string = '';
}
class Stats {
    public prospects: number = -1;
    public delivery: number = -1;
    public invalid: number = -1;
    public bounced: number = -1;
    public queue: number = -1;
    public sent: number = -1;
    public check: number = -1;
    public autoreplied: number = -1;
    public opened: number = -1;
    public optout: number = -1;
    public clicked: number = -1;
    public replied: number = -1;
    public interested: number = -1;
    public maybe_later: number = -1;
    public not_interested: number = -1;
    public emails: Email[] = [];
    public emailsCurrentWeek: Email[] = [];
}
export class Email {
    public subject: string = '';
    public msg: string = '';
    public timezone: string = '';
    public use_prospect_timezone: boolean = false;
    public sunFrom: number = -1;
    public sunTo: number = -1;
    public monFrom: number = -1;
    public monTo: number = -1;
    public tueFrom: number = -1;
    public tueTo: number = -1;
    public wedFrom: number = -1;
    public wedTo: number = -1;
    public thuFrom: number = -1;
    public thuTo: number = -1;
    public friFrom: number = -1;
    public friTo: number = -1;
    public satFrom: number = -1;
    public satTo: number = -1;
    public sunday: Sunday[] = [];
    public monday: Monday[] = [];
    public tuesday: Tuesday[] = [];
    public wednesday: Wednesday[] = [];
    public thursday: Thursday[] = [];
    public friday: Friday[] = [];
    public saturday: Saturday[] = [];
    public track_open: boolean = false;
    public track_click: boolean = false;
    public attach_follow: boolean = false;
    public follow_up: number = -1;
    public number: number = -1;
    public step: number = -1;
    // public object condition { get; set; }
    public emailSend: number = -1;
    public toSend: number = -1;
    public delivery: number = -1;
    public open_: string = '';
    public open: number = -1;
    public reply_: string = '';
    public reply: number = -1;
    public invalid_: string = '';
    public invalid: number = -1;
    public bounce_: string = '';
    public bounce: number = -1;
}
class Sunday {
    public from: number = -1;
    public to: number = -1;
}
class Saturday {
    public from: number = -1;
    public to: number = -1;
}
class Friday {
    public from: number = -1;
    public to: number = -1;
}
class Thursday {
    public from: number = -1;
    public to: number = -1;
}
class Wednesday {
    public from: number = -1;
    public to: number = -1;
}
class Tuesday {
    public from: number = -1;
    public to: number = -1;
}
class Monday {
    public from: number = -1;
    public to: number = -1;
}