import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import DenseTableCell from '../../Utilities/DenseTableCell';
import { CampaignListViewModel } from '../../ViewModels/CampaignViewModel';
import Chip from '@mui/material/Chip';
//import { isNullOrUndefined } from 'util';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
            width: '100%'
        },
        blue: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500],
            width: '100%'
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
            width: '100%'
        },
        lightRed: {
            color: theme.palette.getContrastText('#ff7961'),
            backgroundColor: '#ff7961',
            width: '100%'
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            width: '100%'
        },
        grey: {
            color: theme.palette.getContrastText(grey[500]),
            backgroundColor: grey[500],
            width: '100%'
        },
        default: {
            width: '100%'
        },
    })
);

interface ICampaignListProps {
    campaign: CampaignListViewModel;
    user: any;
}

export default function CampaignTableRow(props: ICampaignListProps) {
    const classes = useStyles();
    const id = props.campaign.id;
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Campaigns/Campaign',
                state: { campaignId: id }
            }}
            {...props} />
    ));


    const statuses = [
        { status: "Live", class: classes.green },
        { status: "Focus", class: classes.amber },
        { status: "Notice", class: classes.lightRed },
        { status: "Cancelled", class: classes.red },
        { status: "Onboarding", class: classes.default },
        { status: "Paused", class: classes.grey },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "DRAFT", class: classes.blue },
        { status: "RUNNING", class: classes.green },
        { status: "EDITED", class: classes.green },
        { status: "COMPLETED", class: classes.default },
        { status: "PAUSED", class: classes.amber },
        { status: "STOPPED", class: classes.red },
        { status: "DELETED", class: classes.red },
        { status: "Unknown", class: classes.red },
    ];

    const statusClass = props.campaign.status.length > 0 ? statuses.find(f => f.status === props.campaign.status)!.class : classes.default;

    return (
        <TableRow>
            <DenseTableCell>{props.campaign.name}</DenseTableCell>
            <DenseTableCell><Chip className={statusClass} label={props.campaign.status} /></DenseTableCell>
            <DenseTableCell>{props.campaign.emails}</DenseTableCell>
            {
                ((props.user?.role !== "Executive" && props.user?.role !== "Creator") || (props.campaign.mainExecutiveId === props.user?.sub)) ?
                    <DenseTableCell align="right"><Button variant="contained" color="secondary" size="small" component={viewButton} >View</Button></DenseTableCell>
                    :
                    <DenseTableCell />
            }
        </TableRow>
    );
}