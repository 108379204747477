import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessIcon from '@mui/icons-material/Business';
//import ContactMailIcon from '@mui/icons-material/ContactMail';
import CampaignCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CssBaseline from '@mui/material/CssBaseline';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import { LoginMenu } from './api-authorization/LoginMenu';
import GroupIcon from '@mui/icons-material/Group';
import './NavMenu.css';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const drawerWidth = 160;
const miniDrawerWidth = 75;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        titleImage: {
            flexGrow: 1
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.primary.main
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,

        },
        miniDrawer: {
            width: miniDrawerWidth,
            flexShrink: 0,
        },
        miniDrawerPaper: {
            width: miniDrawerWidth,
            backgroundColor: theme.palette.primary.main
        },
        drawerContainer: {
            overflow: 'auto',
            color: '#ffffff'
        },
        drawerToggleMin: {
            width: 10,
            position: "fixed",
            top: "50%",
            left: 62,
            zIndex: theme.zIndex.drawer + 1
        },
        drawerToggleMax: {
            width: 10,
            position: "fixed",
            top: "50%",
            left: 227,
            zIndex: theme.zIndex.drawer + 1
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        report: {
            height: "23vh",
            margin: "0",
            width: "220px"
        },
        icon: {
            color: 'inherit'
        },
        padding: {
            paddingLeft: theme.spacing(2),
        },
        avatar: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: '#fcfc8c'
        },
        small: {
            width: theme.spacing(6),
            height: theme.spacing(6),
            color: '#000000',
            backgroundColor: '#fcfc8c',
        },
        highlightOnHover: {
            "&:hover": {
                color: "#fcfc8c",
                textDecoration: "underline #fcfc8c",
                
                
            }
        },

    }),
);

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    secondary?: any;
    to: string;
    show: boolean;
    className: string;
}

function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, secondary, to, show,className} = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    return (
        <li className={className}>
            <ListItem button component={renderLink} style={{ color: 'inherit' }}>
                {icon ? <ListItemIcon style={{ color: 'inherit' }}>{icon}</ListItemIcon> : null}
                {show &&
                    <ListItemText primary={primary} secondary={secondary} />
                }
            </ListItem>
        </li>
    );
}

export default function NavMenu() {
    const classes = useStyles();
    const [role, setRole] = React.useState('');
    const [show, setShow] = React.useState(true);
    const [userName, setUserName] = React.useState('');
    const [name, setName] = React.useState('');
    React.useEffect(() => { getRole(); }, []);

    
    const getRole = async () => {
        const user = await authService.getUser();
        if (user !== null) {
            setRole(user.role);
            setUserName(user.name);
            const token = await authService.getAccessToken();
            
            fetch(`Campaign/GetName`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.text())
                .then(data => {
                    setName(data);
                });
        }
        setShow(true);


    }

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.titleImage}>
                        <img alt="Ignite" src="WhiteLogo.png" height="40" />
                    </div>
                    <List style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        <ListItemLink className={""} to="/Profile" primary={userName} secondary={<Typography variant="caption">{name}</Typography>} icon={<Avatar src="/User/GetAvatar" alt={name} className={classes.small}>{name[0]}</Avatar>} show={show} />
                        </List>
                    <LoginMenu />
                </Toolbar>
            </AppBar>
            {role !== undefined &&
                role.length > 0 &&
                <React.Fragment>
                {
                    // <IconButton className={show ? classes.drawerToggleMax : classes.drawerToggleMin} color="secondary" onClick={handleClick}><SwapHorizontalCircleIcon /></IconButton>
                }
                    <Drawer
                        className={show ? classes.drawer : classes.miniDrawer}
                        variant="permanent"
                        classes={{
                            paper: show ? classes.drawerPaper : classes.miniDrawerPaper,

                        }}
                    >
                        <Toolbar />
                        <div className={classes.drawerContainer}>
                            <List>
                                {role !== undefined ?
                                    role.length > 0 &&
                                <React.Fragment>
                                    <ListItemLink className={classes.highlightOnHover} to="/Clients" primary="Clients" icon={<BusinessIcon className={classes.icon} />} show={show} />
                                    {/*<ListItemLink className={classes.highlightOnHover} to="/Campaigns" primary="Campaigns" icon={<ContactMailIcon className={classes.icon} />} show={show} />*/}
                                    <ListItemLink className={classes.highlightOnHover} to="/EmailReports" primary="Reports" icon={<AssessmentIcon className={classes.icon} />} show={show} />
                                    {(role === "Admin" || role === "SuperAdmin") && <ListItemLink className={classes.highlightOnHover} to="/Teams" primary="Teams" icon={<GroupIcon className={classes.icon} />}  show={show} />}
                                    {(role === "Admin" || role === "SuperAdmin") && <ListItemLink className={classes.highlightOnHover} to="/Admin" primary="Admin" icon={<CampaignCircleIcon className={classes.icon} />} show={show} />}
                                    { (role === "Admin" || role === "SuperAdmin") && <ListItemLink className={classes.highlightOnHover} to="/PowerBI" primary="PowerBI" icon={<AssessmentIcon className={classes.icon} />}  show={show} />}
                                        
                                    </React.Fragment>
                                    : <React.Fragment />
                                }
                            </List>
                        </div>
                    </Drawer>
                </React.Fragment>
            }
        </React.Fragment>
    );
}
