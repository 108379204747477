//import * as React from 'react';
//import { Theme } from '@mui/material/styles';
//import createStyles from '@mui/styles/createStyles';
//import makeStyles from '@mui/styles/makeStyles';
import WoodpeckerViewModel from './ViewModels/WoodpeckerViewModel';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import blue from '@mui/material/colors/blue';

//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//            marginBottom: theme.spacing(2),
//        },
//        notes: {
//            whiteSpace: 'pre-wrap'
//        }
//    })
//);

interface IEmailStatsProps {
    woodpeckerVM: WoodpeckerViewModel;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: blue[900],
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function EmailStats(props: IEmailStatsProps) {
    // const classes = useStyles();
    // const { user, clientNote, clientId } = props;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Account</StyledTableCell>
                        <StyledTableCell align="right">Step</StyledTableCell>
                        <StyledTableCell align="right">Emails Sent</StyledTableCell>
                        <StyledTableCell align="right">Number Opened</StyledTableCell>
                        <StyledTableCell align="right">Opened %</StyledTableCell>
                        <StyledTableCell align="right">Opt out</StyledTableCell>
                        <StyledTableCell align="right">Replied</StyledTableCell>
                        <StyledTableCell align="right">Leads Generated</StyledTableCell>
                        <StyledTableCell align="right">Maybe</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.woodpeckerVM.stats.emailsCurrentWeek.map((row) => (
                        <StyledTableRow key={row.number}>
                            <StyledTableCell component="th" scope="row">
                                {props.woodpeckerVM.from_email}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.step}</StyledTableCell>
                            <StyledTableCell align="right">{row.emailSend}</StyledTableCell>
                            <StyledTableCell align="right">{row.open}</StyledTableCell>
                            <StyledTableCell align="right">{row.open_}</StyledTableCell>
                            <StyledTableCell align="right">{props.woodpeckerVM.stats.optout}</StyledTableCell>
                            <StyledTableCell align="right">{row.reply}</StyledTableCell>
                            <StyledTableCell align="right">{props.woodpeckerVM.stats.interestedCurrentWeek}</StyledTableCell>
                            <StyledTableCell align="right">{props.woodpeckerVM.stats.maybe_laterCurrentWeek}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
}