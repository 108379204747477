import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { OpportunityViewModel } from '../../ViewModels/CampaignViewModel';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import LeadListItem from './LeadListItem';
import Grow from '@mui/material/Grow';
import Pagination from '@mui/material/Pagination';
interface IListProps {
    campaignId: number;
    active: boolean;
}

export default function LeadList(props: IListProps) {
    const [leads, setLeads] = React.useState<OpportunityViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageMax, setPageMax] = React.useState(1);
    const [filteredLeads, setFilteredLeads] = React.useState<OpportunityViewModel[]>([]);
    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Woodpecker/GetWoodpeckerLeads?campaignId=${props.campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data: OpportunityViewModel[]) => {
                setLeads(data);
                setLoaded(true);
                setPageMax(Math.ceil(data.length / 10));
                setFilteredLeads(data.slice(0,10))
            })
            .catch(error => {
                console.log('Lead list error: ' + error);
                setLoaded(true);
            });
    }, [props.campaignId]);
    React.useEffect(() => {
        getData();
    }, [getData]);

    

    const items = filteredLeads.map((lead, index) => {
        return <LeadListItem key={index} lead={lead} refresh={getData} />;
    });
    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue);
        let l = leads.slice();
        setFilteredLeads(l.splice((newValue - 1) * 10, 10));
    };
    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">Opportunities</Typography>
                </Grid>
            </Grid>
            {!loaded ? <LinearProgress /> :
                <Grow in={loaded}
                    {...(loaded ? { timeout: 1000 } : {})}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {items}
                        </Grid>
                        <Grid item xs={12} alignItems="center">
                            <Pagination page={page} count={pageMax} size="small" onChange={handlePageChange} showFirstButton showLastButton />
                        </Grid>                        
                    </Grid>
                </Grow>
            }
        </React.Fragment>
    );
}