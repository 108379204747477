import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import authService from '../api-authorization/AuthorizeService';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import TeamViewModel from './TeamViewModel';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import UserDropdown from '../Dropdowns/UserDropdown';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        paper: {
            width: 200,
            height: 450,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    }),
);
function not(a: DropdownViewModel[], b: DropdownViewModel[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: DropdownViewModel[], b: DropdownViewModel[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
interface ITeamListProps {
    teamLeaders: DropdownViewModel[];
    team: TeamViewModel;
    roles: string | string[];
    refresh: () => void;
    users: DropdownViewModel[];
}

export default function TeamTableRow(props: ITeamListProps) {
    const classes = useStyles();
    const [openUserDialog, setDialogOpen] = React.useState(false);
    const [openWarningDialog, setWarningOpen] = React.useState(false);
    const [checked, setChecked] = React.useState<DropdownViewModel[]>([]);
    const [teamLeaderId, setTeamLeaderId] = React.useState(props.team.teamLeaderId);
    const [left, setLeft] = React.useState<DropdownViewModel[]>(props.users.filter(function (obj) {
        return props.team.members.filter(f => f.id === obj.id).length === 0;
    }));
    const [right, setRight] = React.useState<DropdownViewModel[]>(props.team.members);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    React.useEffect(() => {
        setLeft(props.users.filter(function (obj) {
            return props.team.members.filter(f => f.id === obj.id).length === 0;
        }));
        setRight(props.team.members);
    }, [props.team, props.users]);
    const openUserManager = () => {
        setDialogOpen(true);
    }
    const handleUserClose = (refresh: boolean) => {
        if (refresh) {
            props.refresh();
        }
        setDialogOpen(false);
    }
    const openArchiveDialog = () => {
        setWarningOpen(true);
    }

    const handleWarningClose = () => {
        setWarningOpen(false);
    }



    const deleteTeam = async () => {
        const token = await authService.getAccessToken();

        fetch(`Team/RemoveTeam?teamId=${props.team.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    props.refresh();
                    setWarningOpen(false);
                }
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    }
    const submitMembers = async () => {
        const token = await authService.getAccessToken();

        fetch(`Team/SubmitMembers?teamId=${props.team.id}&teamLeaderId=${teamLeaderId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(right)
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    handleUserClose(true);
                }
            })
            .catch(error => { });
    }
    const handleToggle = (value: DropdownViewModel) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const customList = (items: DropdownViewModel[]) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((value: DropdownViewModel) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    size="small"
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.value}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    const onChange = (value: number | string, name: string, client: string) => {
        setTeamLeaderId(value as string);
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.team.name}</TableCell>

                {
                    props.roles !== "Executive" &&
                    <React.Fragment>
                        <TableCell align="right">{props.roles !== "Creator" && <Button size="small" variant="outlined" color="secondary" onClick={openUserManager}>Users</Button>}</TableCell>
                        <TableCell align="right">{props.roles !== "Creator" && <Button size="small" variant="outlined" color="secondary" onClick={openArchiveDialog}>Delete Team</Button>}</TableCell>
                    </React.Fragment>
                }
            </TableRow>
            <Dialog
                open={openWarningDialog}
                onClose={handleWarningClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-description">
                        This will permanently delete this Team.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWarningClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={deleteTeam} color="primary" autoFocus>
                        Delete
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog
                open={openUserDialog}
                onClose={handleUserClose}
            >
                <DialogTitle >{"User Manager"}</DialogTitle>
                <DialogContent>
                    <UserDropdown users={props.teamLeaders} id={teamLeaderId} label="Team Leader" name="userId" onChange={onChange} required={false} />

                    <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                        <Grid item>{customList(left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList(right)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUserClose(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => submitMembers()} color="primary" autoFocus>
                        Save Changes
                    </Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}