import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ClientViewModel from '../ViewModels/ClientViewModel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import TeamLeaderDialog from './TeamLeaderDialog';
import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import authService from '../api-authorization/AuthorizeService';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import { Theme } from '@mui/material/styles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
            width: '100%'
        },
        blue: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500],
            width: '100%'
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
            width: '100%'
        },
        lightRed: {
            color: theme.palette.getContrastText('#ff7961'),
            backgroundColor: '#ff7961',
            width: '100%'
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            width: '100%'
        },
        grey: {
            color: theme.palette.getContrastText(grey[500]),
            backgroundColor: grey[500],
            width: '100%'
        },
        default: {
            width: '100%'
        },
    })
);
interface IClientListProps {
    teamLeaders: DropdownViewModel[];
    client: ClientViewModel;
    roles: string | string[];
    refresh: () => void;
}

export default function ClientTableRow(props: IClientListProps) {
    const id = props.client.id;
    const classes = useStyles();
    const [openTLeaderDialog, setDialogOpen] = React.useState(false);
    const [openWarningDialog, setWarningOpen] = React.useState(false);
    const statuses = [
        { status: "Live", class: classes.green },
        { status: "Pending", class: classes.amber },
        { status: "Notice", class: classes.lightRed },
        { status: "Cancelled", class: classes.red },
        { status: "Onboarding", class: classes.default },
        { status: "Complete", class: classes.default },
        { status: "Paused", class: classes.grey },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "DRAFT", class: classes.blue },
        { status: "RUNNING", class: classes.green },
        { status: "EDITED", class: classes.green },
        { status: "COMPLETED", class: classes.default },
        { status: "PAUSED", class: classes.amber },
        { status: "STOPPED", class: classes.red },
        { status: "DELETED", class: classes.red },
        { status: "Unknown", class: classes.red },
    ];
    
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Client',
                state: { clientId: id }
            }}
            {...props} />
    ));
    const openDialog = () => {
        setDialogOpen(true);
    }

    const openArchiveDialog = () => {
        setWarningOpen(true);
    }

    const handleWarningClose = () => {
        setWarningOpen(false);
    }

    const handleClose = (refresh: boolean) => {
        if (refresh) {
            props.refresh();
        }
        setDialogOpen(false);
    }

    const archiveClient = async () => {
        const token = await authService.getAccessToken();

        fetch(`Client/ArchiveClient?id=${props.client.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    props.refresh();
                    setWarningOpen(false);
                }
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    }

    let teamLeader = <TableCell />;
    if (props.client.teamLeader && props.client.teamLeader.length > 0) {
        teamLeader = <TableCell>
            {props.client.teamLeader}
            {(props.roles !== "Executive" && props.roles !== "Creator") && <IconButton size="small" onClick={openDialog} style={{ marginLeft: 8 }}><Edit color="secondary" /></IconButton>}
        </TableCell>;
    }
    else if (props.roles !== "Executive" && props.roles !== "Creator") {
        teamLeader = <TableCell><Button size="small" variant="outlined" color="secondary" onClick={openDialog}>Set Account Manager</Button></TableCell>;
    } else {
        teamLeader = <TableCell>No Team Leader</TableCell>;
    }
    const statusClass = props.client.status.length > 0 ? statuses.find(f => f.status === props.client.status)!.class : classes.default;
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{props.client.clientName}</TableCell>
                <TableCell>{props.client.clientType}</TableCell>
                <TableCell>{new Date(props.client.startDate).toLocaleDateString("en-GB")}</TableCell>
                {teamLeader}
                <TableCell><Chip className={statusClass} label={props.client.status} /></TableCell>
                <TableCell align="right"><Button variant="contained" style={{ textAlign: 'center' }} color="primary" size="small" component={viewButton} >View Client</Button></TableCell>
                {
                    props.roles !== "Executive" &&
                    <React.Fragment>
                        <TableCell align="right">{props.roles !== "Creator" && <Button size="small" variant="outlined" color="secondary" style={{ textAlign: 'center' }} onClick={openArchiveDialog}>Archive Client</Button>}</TableCell>
                    </React.Fragment>
                }
            </TableRow>
            <TeamLeaderDialog users={props.teamLeaders} clientId={props.client.id} open={openTLeaderDialog} close={handleClose} />
            <Dialog
                open={openWarningDialog}
                onClose={handleWarningClose}
                aria-labelledby="warning-title"
                aria-describedby="warning-description"
            >
                <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-description">
                        This will permanently archive this client and its campaigns.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWarningClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={archiveClient} color="primary" autoFocus>
                        Archive
                    </Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}