import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PackageDropdown from '../Dropdowns/PackageDropdown';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../Utilities/Alert';
import authService from '../api-authorization/AuthorizeService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    }),
);

export default function UpdatePackages() {
    const classes = useStyles();
    const [toUpdate, setPackage] = React.useState(0);
    const [newPrice, setPrice] = React.useState(0.00);
    const [updating, setUpdating] = React.useState(false);
    const [alert, setAlert] = React.useState(<React.Fragment />);
    const [showAlert, setShow] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPackage(parseInt(event.target.value));
    }

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(event.target.valueAsNumber);
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const openAlert = (message: string, error: boolean) => {
        setAlert(
            <Alert onClose={handleClose} severity={error ? "error" : "success"}>
                {message}
            </Alert>
        );
        setShow(true);
    }

    const updatePackage = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        fetch(`Campaign/UpdatePackagePrice?id=${toUpdate}&price=${newPrice}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("Package updated successfully", false);
                    setUpdating(false);
                } else {
                    openAlert("Failed to update package, please try again.", true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                openAlert("Failed to update package, please try again.", true);
                setUpdating(false);
            });
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="h2">Update Package</Typography>
            <Grid container>
                <Grid item xs={12}>
                    <PackageDropdown
                        id={toUpdate}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="New Price"
                        type="number"
                        inputProps={{
                            min: 0.00,
                            pattern: "^\d*(\.\d{0,2})?$"
                        }}
                        value={newPrice}
                        onChange={handlePriceChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" disabled={newPrice <= 0 || updating} onClick={updatePackage}>
                        Update Package
                        {updating && <CircularProgress size={20} className={classes.buttonProgress} />}
                    </Button>
                </Grid>
            </Grid>
            {showAlert &&
                <Grid item xs={12}>
                    {alert}
                </Grid>
            }
        </Paper>
    );
}