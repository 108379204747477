import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ReportViewModel from "../../ViewModels/ReportViewModel";

interface IProps {
    row: ReportViewModel
}

export default function EmailReportTableRow(props: IProps) {
    const { row } = props;
    return (
        <TableRow key={row.campaignId}>
            <TableCell>{row.campaignName}</TableCell>
            <TableCell>{row.clientName}</TableCell>
            <TableCell>{row.country}</TableCell>
            <TableCell>
                <Tooltip title={"Price: " + row.price}>
                    <div>{row.packageName}</div>
                </Tooltip>
            </TableCell>
            <TableCell align="right">{row.earliestRecord}</TableCell>
            <TableCell align="right">{row.latestRecord}</TableCell>
            <TableCell align="right">{row.sent}</TableCell>
            <TableCell align="right">{row.opened} ({row.openedPercentage.toFixed()}%)</TableCell>
            <TableCell align="right">{row.clicked}</TableCell>
            <TableCell align="right">{row.interested}</TableCell>
        </TableRow>
    );
}