import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import  { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LeadEmailViewModel from './ViewModels/LeadEmailViewModel';
import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';
import DenseTableCell from '../Utilities/DenseTableCell';

interface ILeadEmailTableProps {
    leadEmailVM: LeadEmailViewModel[];
    interestedTable: boolean;
}
var interested = false;
const StyledTableCell = styled(DenseTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: interested ? green[900] : amber[900],
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function LeadEmailTable(props: ILeadEmailTableProps) {
    interested = props.interestedTable;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {interested ?
                            <StyledTableCell align="center" colSpan={4}>
                                Interested
                            </StyledTableCell>
                            :
                            <StyledTableCell align="center" colSpan={4}>
                                Maybe
                            </StyledTableCell>
                            }
                    </TableRow>
                    <TableRow>
                        <StyledTableCell >Date</StyledTableCell>
                        <StyledTableCell align="right">Name</StyledTableCell>
                        <StyledTableCell align="right">Email Address</StyledTableCell>
                        <StyledTableCell align="right">Response</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.leadEmailVM.map((row) => (
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                                {row.date}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.email}</StyledTableCell>
                            <StyledTableCell align="right">{<div className="content" dangerouslySetInnerHTML={{ __html: row.response }}></div>}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
}