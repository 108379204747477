import * as React from 'react';
import authService from './api-authorization/AuthorizeService';
import { Redirect } from 'react-router';

export function Home() {
    const [role, setRole] = React.useState('');

    React.useEffect(() => { getRole(); }, []);

    const getRole = async () => {
        const user = await authService.getUser();
        if (user !== null) {
            setRole(user.role);
        }
    }

    return (
        <React.Fragment>
            {role && role.length > 0 &&
                <React.Fragment>
                {(role !== "Executive" && role !== "Creator") ?
                        <Redirect to="/Clients" />
                        :
                        <Redirect to="/Campaigns" />
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}
