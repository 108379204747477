import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CreateClientViewModel } from '../ViewModels/ClientViewModel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import authService from '../api-authorization/AuthorizeService';
import { Redirect } from 'react-router';
import UserDropdown from '../Dropdowns/UserDropdown';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import TextField from '@mui/material/TextField';
import CountryDropdown from '../Dropdowns/CountryDropdown';
import PackageDropdown from '../Dropdowns/PackageDropdown';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    })
);

export default function CreateClient() {
    const classes = useStyles();

    const [client, setClient] = React.useState(new CreateClientViewModel());
    const [success, setSuccess] = React.useState(false);
    const [nameFree, setNameFree] = React.useState(true);
    const [serverError, setError] = React.useState('');
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => { getData(); }, []);


    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClient({
            ...client,
            [event.target.name]: event.target.value
        });
    }

    const onDropdownChange = (value: number | string) => {
        setClient({
            ...client,
            teamLeader: value as string
        })
    }

    const submit = async () => {
        const token = await authService.getAccessToken();

        fetch(`Client/CheckClientName?name=${client.clientName}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setNameFree(data);
                if (data) {
                    fetch('Client/CreateClient', {
                        method: 'POST',
                        headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                        body: JSON.stringify(client)
                    })
                        .then(response => response.text())
                        .then(data => {
                            if (data === 'Ok') {
                                setSuccess(true);
                            } else {
                                setError('A server error has occurred, please try again.');
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            setError('A server error has occurred, please try again.');
                        });
                }
            })
            .catch(error => {
                console.log('Check name error: ' + error);
            });
    }

    return (
        <React.Fragment>
            {success && <Redirect to='/Clients' />}
            <ValidatorForm onSubmit={submit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Create Client</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        {
                            !nameFree &&
                            <Grid item xs={12}>
                                <Typography variant="body1" color="error">Client already exists with this name</Typography>
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <TextValidator
                                label="Client Name"
                                name="clientName"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={client.clientName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextValidator
                                label="Contact Name"
                                name="contactName"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={client.contactName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextValidator
                                label="Contact Email"
                                name="contactEmail"
                                variant="outlined"
                                margin="normal"
                                type="email"
                                fullWidth
                                value={client.contactEmail}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Invalid email format']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextValidator
                                label="Contact Phone"
                                name="contactPhone"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={client.contactPhone}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <PackageDropdown id={client.ref_PackageId} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    showToolbar={false}
                                    inputFormat="dd/MM/yyyy"
                                    mask="dd/MM/yyyy"
                                    label="Start Date"
                                    value={client.startDate}
                                    onChange={(newValue) => {
                                        setClient({
                                            ...client,
                                            startDate: newValue as Date
                                        });
                                    }}
                                    renderInput={(params) => <TextField margin="normal" fullWidth variant="outlined" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <UserDropdown
                                id={client.teamLeader}
                                name="teamLeader"
                                label="Client Manager"
                                users={users}
                                onChange={onDropdownChange}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CountryDropdown id={client.ref_CountryId} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" type="submit">Create</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </ValidatorForm>
        </React.Fragment>
    );
}