import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            minHeight: '300px',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        notes: {
            whiteSpace: 'pre-wrap'
        }
    })
);

interface IWoodpeckerNoInfoProps {
}

export default function WoodpeckerNoInfo(props: IWoodpeckerNoInfoProps) {
    const classes = useStyles();
    // const { user, clientNote, clientId } = props;

   
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Typography variant="h4" align="center" textAlign="center">Woodpecker Campaign not found</Typography>
            </Paper>
        </React.Fragment>
    );
}