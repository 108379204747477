import * as React from 'react';
import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
import RegisterUser from './RegisterUser';
import ManageUsers from './ManageUsers';
import UpdatePackages from './UpdatePackages';
import authService from '../api-authorization/AuthorizeService';

export default function AdminDashboard() {
    const [Access, SetAccess] = React.useState(false);

    React.useEffect(() => {
        authService.getUser().then(user => {
            if (user.role == "Admin" || user.role == "SuperAdmin") {

                SetAccess(true);
            }
        });
    }, []);

    return (
        <React.Fragment>
            { Access &&
                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <RegisterUser />
                    </Grid>
                    <Grid item xs={6}>
                        <ManageUsers />
                    </Grid>
                    <Grid item xs={6}>
                        <UpdatePackages />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}