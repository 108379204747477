import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
// import Typography from '@mui/material/Typography';

export default function PowerBI() {

    const [Access, SetAccess] = React.useState(false);
    React.useEffect(() => {
        authService.getUser().then(user => {
            if (user.role == "Admin" || user.role == "SuperAdmin") {

                SetAccess(true);
            }
        });
    }, []);



    return (
        <React.Fragment>
            {
                Access &&
                <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden', paddingTop:'10px' }}>
                    <iframe style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0, height: 'calc(100vh - 100px)' }} src="https://app.powerbi.com/reportEmbed?reportId=91cc14b4-55d3-44d2-8e60-eca38a83f851&autoAuth=true&ctid=7c73f318-8050-40b6-b6d2-60f617aa1490&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" ></iframe>
                </div>
            }
        </React.Fragment>
    );
}