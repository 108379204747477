//import * as React from 'react';
//import { Theme } from '@mui/material/styles';
//import createStyles from '@mui/styles/createStyles';
//import makeStyles from '@mui/styles/makeStyles';
import { Email } from './WoodpeckerViewModel';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//            marginBottom: theme.spacing(2),
//        },
//        notes: {
//            whiteSpace: 'pre-wrap'
//        }
//    })
//);

interface IEmailStatsProps {
    email: Email[];
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function EmailStats(props: IEmailStatsProps) {
    // const classes = useStyles();
    // const { user, clientNote, clientId } = props;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Number</StyledTableCell>
                        <StyledTableCell align="right">Step</StyledTableCell>
                        <StyledTableCell align="right">Sent</StyledTableCell>
                        <StyledTableCell align="right">Delivered</StyledTableCell>
                        <StyledTableCell align="right">Open</StyledTableCell>
                        <StyledTableCell align="right">Open%</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.email.map((row) => (
                        <StyledTableRow key={row.number}>
                            <StyledTableCell component="th" scope="row">
                                {row.number}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.step}</StyledTableCell>
                            <StyledTableCell align="right">{row.emailSend}</StyledTableCell>
                            <StyledTableCell align="right">{row.delivery}</StyledTableCell>
                            <StyledTableCell align="right">{row.open}</StyledTableCell>
                            <StyledTableCell align="right">{row.open_}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
}