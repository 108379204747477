import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

interface IDatePickerProps {
    date: Date | null;
    name: string;
    label: string;
    disablePast: boolean;
    disableFuture: boolean;
    monthOnly?: boolean;
    onChange: (date: Date | null, name: string) => void;
}

export default function Datepicker(props: IDatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                views={props.monthOnly ? ["year", "month"] : ["year", "month", "day"]}
                openTo={props.monthOnly ? "month" : undefined}
                inputFormat={props.monthOnly ? "MM" : "dd/MM/yyyy"}
                mask={props.monthOnly ? "MM" : "dd/MM/yyyy"}
                label={props.label}
                value={props.date ?? new Date()}
                onChange={(newValue : any) => {
                    props.onChange(newValue as Date, props.name);
                }}
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                renderInput={(params) => <TextField margin="normal" fullWidth variant="outlined" {...params} />}
            />
        </LocalizationProvider>
    );
}