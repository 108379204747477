export default class CampaignViewModel {
    public id: number = 0;
    public name: string = '';
    public userName: string = '';
    public password: string = '';
    public campaignHolderEmail: string = '';
    public googleSheetLink: string = '';    
    public clientId: number = 0;
    public clientName: string = '';
    public clientEmail: string = '';
    public clientPhone: string = '';
    public clientPartner: string = '';
    public packageId: number = 0;
    public packageName: string = '';
    public price: number = 0;
    public countryId: number = 0;
    public country: string = '';
    public agreementLength: string = '';
    public opportunities: OpportunityViewModel[] = [];
    public executives: string[] = [];
    public status: string = '';
    public clientTeamLeaderId: string = '';
    public clientTeamLeader: string = '';
    public mainExecutiveId: string = '';
    public mainExecutive: string = '';
    public vpnNumber: string = "";
    public woodPeckerId: string = '';
}

export class CampaignListViewModel {
    public id: number = 0;
    public name: string = '';
    public username: string = '';
    public password: string = '';
    public clientId: number = 0;
    public clientName: string = '';
    public packageName: string = '';
    public price: string = '';
    public country: string = '';
    public activityLogged: boolean = false;
    public opportunities: number = 0;
    public status: string = '';
    public archived: boolean = false;
    public isManager: boolean = false;
    public isExecutive: boolean = false;
    public mainExecutive: string = '';
    public userCampaigns: []=[];
    public mainExecutiveId: any;
    public isTeamLeader: boolean = false;
    public emails: number = 0;
}

export class OpportunityViewModel {
    public campaignId: number = 0;
    public name: string = '';
    public link: string = '';
    public companyName: string = '';
    public notes: string = '';
    public email: string = '';
    public phoneNumber: string = '';
    public id: number = 0;
    public timestamp: Date = new Date();
    public interested: string = "";
}

export class UserCampaignViewModel {
    public userId: string = '';
    public campaignId: number = 0;
    public startDate: Date = new Date();
    public endDate: Date | null = null;
}

export class UserCampaignListViewModel {
    public id: number = 0;
    public campaignId: number = 0;
    public name: string = '';
    public temp: boolean = false;
}

export class MessageViewModel {
    public id: number = 0;
    public title: string = '';
    public content: string = '';
    public campaignId: number = 0;
}

export class CreateMessageViewModel {
    public title: string = '';
    public content: string = '';
    public campaignId: number = 0;

    public constructor(id: number) {
        this.campaignId = id;
        this.title = '';
        this.content = '';
    }
}

export class NoteViewModel {
    public id: number = 0;
    public author: string = '';
    public content: string = '';
    public type: string = '';
    public subType: string = '';
    public timestamp: Date = new Date();
    public client: boolean = false;
    public applicationContent: string = '';
}

export class CreateNoteViewModel {
    public content: string = '';
    public commonReason: string = '';
    public noteTypeId: number = 0;
    public id: number = 0;
    public statusTo: string = '';
    public constructor(id: number) {
        this.id = id;
        this.noteTypeId = 0;
        this.content = '';
    }
}

export class CreatePackageViewModel {
    public campaignId: number = 0;
    public packageName: string = '';
    public price: number = 0;
    public custom: boolean = false;
}

