import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CustomerSatisfactionSurveyViewModel } from '../ViewModels/CustomerSatisfactionSurveyViewModel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    }),
);

interface ICustomerSatisfactionSurveyProps {
    surveyId: string;
}

export default function CustomerSatisfactionSurvey(props: ICustomerSatisfactionSurveyProps) {
    const { surveyId } = props;
    const classes = useStyles();
    const [survey, setSurvey] = React.useState(new CustomerSatisfactionSurveyViewModel(surveyId));
    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState<boolean | false>();
    const [emptyFields, setEmptyFields] = React.useState<boolean>(false);
    const [surveyValid, setSurveyValid] = React.useState<boolean | null>(null);

    React.useEffect(() => { getSurvey(); }, []);

    const getSurvey = async () => {

        fetch(`CustomerSurveySatifaction/GetSurvey?surveyId=${survey.surveyId}`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setSurveyValid(false);
                }
                else {
                    setSurveyValid(true)
                }
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }

    const submitResults = async () => {

        fetch('CustomerSurveySatifaction/SubmitSurveyResults', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(survey)
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setSubmitted(true);
                }
                else {
                    setOpen(true);
                }
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
                setOpen(true);
            });
    }
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            setOpen(false)
            return;
        }
    }

    const pressSubmit = () => {
        if (survey.question1Score != null && survey.question2Score != null
            && survey.question3Score != null && survey.question4Score != null) {
            setEmptyFields(false);

            submitResults();
        }
    };

    const handleChange = (name: any, newValue: any) => {
        console.log(newValue)
        setSurvey({
            ...survey,
            [name]: newValue
        });
    }

    const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseInt(e.target.value);
        setSurvey({
            ...survey,
            [e.target.name]: val
        });
    }

    return surveyValid === null ?
            <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={4} justifyContent="center" />
                <Grid item xs={4} justifyContent="center">
                    <CircularProgress />
                </Grid>
                <Grid item xs={4} justifyContent="center" />
            </Grid>
            :
            (!surveyValid ?
                (!submitted ?
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} lg={8} justifyContent="center">
                            <Paper className={classes.paper}>
                                <Typography>How would you rate the quantity of the leads generated last week?</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <FormControl>
                                            <RadioGroup row name="question1Score" value={survey.question1Score} onChange={handleChangeNumber}>
                                                <FormControlLabel value={1} control={<Radio />} label="Very Poor" />
                                                <FormControlLabel value={2} control={<Radio />} label="Poor" />
                                                <FormControlLabel value={3} control={<Radio />} label="Good" />
                                                <FormControlLabel value={4} control={<Radio />} label="Very Good" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} justifyContent="center">
                            <Paper className={classes.paper}>
                                <Typography>How would you rate the quality of the leads generated last week?</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <FormControl>
                                            <RadioGroup row name="question2Score" value={survey.question2Score} onChange={handleChangeNumber}>
                                                <FormControlLabel value={1} control={<Radio />} label="Very Poor" />
                                                <FormControlLabel value={2} control={<Radio />} label="Poor" />
                                                <FormControlLabel value={3} control={<Radio />} label="Good" />
                                                <FormControlLabel value={4} control={<Radio />} label="Very Good" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} justifyContent="center">
                            <Paper className={classes.paper}>
                                <Typography>How would you rate the communication?</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <FormControl>
                                            <RadioGroup row name="question3Score" value={survey.question3Score} onChange={handleChangeNumber}>
                                                <FormControlLabel value={1} control={<Radio />} label="Very Poor" />
                                                <FormControlLabel value={2} control={<Radio />} label="Poor" />
                                                <FormControlLabel value={3} control={<Radio />} label="Good" />
                                                <FormControlLabel value={4} control={<Radio />} label="Very Good" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} justifyContent="center">
                            <Paper className={classes.paper}>
                                <Typography>Overall how satisfied are you with client?</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <FormControl>
                                            <RadioGroup row name="question4Score" value={survey.question4Score} onChange={handleChangeNumber}>
                                                <FormControlLabel value={1} control={<Radio />} label="Very Poor" />
                                                <FormControlLabel value={2} control={<Radio />} label="Poor" />
                                                <FormControlLabel value={3} control={<Radio />} label="Good" />
                                                <FormControlLabel value={4} control={<Radio />} label="Very Good" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} justifyContent="center">

                            <Paper className={classes.paper}>
                                <Typography>Additional comments (optional):</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    value={survey.notes}
                                    onChange={(event) => {
                                        handleChange('notes', event.target.value);
                                    }}
                                />
                            </Paper>
                        </Grid>
                        <Snackbar open={open} autoHideDuration={5} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error">
                                Something went wrong. Please try again.
                            </Alert>
                        </Snackbar>

                        <Grid item xs={12} sm={12} lg={8} justifyContent="center">
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={12}>
                                    {emptyFields &&
                                        <Typography variant='subtitle2' color="secondary">
                                            Please ensure you've answered all questions.
                                </Typography>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={pressSubmit}>
                                        Submit
                            </Button>
                                </Grid>
                            </Grid>
                        </Grid >
                    </Grid>
                    :
                    <Paper className={classes.paper}>
                        <Typography>Thank you for your feedback.</Typography>
                    </Paper>
                )
                :
                <Paper className={classes.paper}>
                    <Typography>Sorry, this survey is no longer active.</Typography>
                </Paper>
            );

}