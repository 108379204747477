import NavMenu from './NavMenu';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(0),
            paddingTop: theme.spacing(1),
            width: 'calc(100% - 240px)',
            backgroundColor: '#ffffff'
        },
    }),
);

interface IProps {
    children: any;
}

const Layout = (props: IProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
                <NavMenu />
            <main className={classes.content}>
                <Toolbar />
                <Container maxWidth="xl">
                    {props.children}
                </Container>
            </main>
        </div>
    );
}
export default Layout;