import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { MessageViewModel } from '../../ViewModels/CampaignViewModel';
import Button from '@mui/material/Button';
import authService from '../../api-authorization/AuthorizeService';
import AccordionActions from '@mui/material/AccordionActions';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CopyToClipboard from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        message: {
            whiteSpace: 'pre-wrap'
        }
    }),
);

class EditMessage {
    messageId: number = 0;
    message: string = '';

    constructor(messId: number, mess: string) {
        this.messageId = messId;
        this.message = mess;
    }
}

interface IMessageProps {
    message: MessageViewModel;
    refresh: () => void;
}

export default function Message(props: IMessageProps) {
    const classes = useStyles();
    const [editing, setEditing] = React.useState(false);
    const [newMessage, setMessage] = React.useState(props.message.content);

    const deleteMessage = async () => {
        const token = await authService.getAccessToken();

        fetch(`Client/DeleteMessage?messageId=${props.message.id}&clientId=${props.message.campaignId}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.text())
            .then(data => {
                props.refresh();
            })
            .catch(error => {
                console.log('Delete message error: ' + error);
            });
    }

    const editMessage = async () => {
        const token = await authService.getAccessToken();

        fetch(`Client/UpdateMessage`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(new EditMessage(props.message.id, newMessage))
        })
            .then(response => response.text())
            .then(data => {
                setEditing(false);
                props.refresh();
            })
            .catch(error => {
                console.log('Delete message error: ' + error);
            });
    }

    const handleEditing = () => {
        setEditing(!editing);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={props.message.id + "-content"}
                id={props.message.id + "-header"}
            >
                <Typography className={classes.heading}>{props.message.title}</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
                {editing ?
                    <TextField className={classes.message} fullWidth multiline variant="outlined" value={newMessage} onChange={handleChange} />
                    :
                    <Typography className={classes.message}>{props.message.content}</Typography>
                }
            </AccordionDetails>
            <Divider />
            <AccordionActions>
                {editing ?
                    <React.Fragment>
                        <Button size="small" variant="outlined" color="secondary" onClick={handleEditing}>Cancel</Button>
                        <Button size="small" variant="outlined" color="primary" onClick={editMessage}>Save</Button>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Button size="small" variant="outlined" color="secondary" onClick={deleteMessage}>Remove</Button>
                        <Button size="small" variant="outlined" color="primary" onClick={handleEditing}>Edit</Button>
                        <CopyToClipboard text={props.message.content}>
                            <Button size="small" variant="outlined" color="primary">Copy</Button>
                        </CopyToClipboard>
                    </React.Fragment>
                }
            </AccordionActions>
        </Accordion>
    );
}