import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// import Paper from '@mui/material/Paper';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WoodpeckerViewModel from './WoodpeckerViewModel';
import GeneralInfo from './GeneralInfo';
import Stats from './Stats';
import Divider from '@mui/material/Divider';
import EmailStats from './EmailStats';
import Button from '@mui/material/Button';
import { CSVLink } from 'react-csv';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import enLocale from 'date-fns/locale/en-GB';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            // marginBottom: theme.spacing(2)
        },
        notes: {
            whiteSpace: 'pre-wrap'
        },
        csvButton: {
            color: 'inherit',
            textDecoration: 'inherit'
        }
    })
);

interface IWoodpeckerInfoProps {
    woodpeckerDetails: WoodpeckerViewModel;
    updateData: (startDate: Date | null, endDate: Date | null) => void;
}
const getStartOfWeek = (date: Date) => {
    var newDate = new Date(date.getTime() - (1000 * 60 * 60 * 24 * date.getDay()));
    return newDate;
}
const getEndOfWeek = (date: Date) => {
    var newDate = new Date(date.getTime() + (1000 * 60 * 60 * 24 * (6 - date.getDay())));
    return newDate;
}
export default function WoodpeckerInfo(props: IWoodpeckerInfoProps) {
    const classes = useStyles();
    // const { user, clientNote, clientId } = props;
    const [startDate, setStartDate] = React.useState<Date | null>(getStartOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState<Date | null>(getEndOfWeek(new Date()));

    React.useEffect(() => props.updateData(startDate, endDate), [startDate, endDate]);

    const emails = <EmailStats email={props.woodpeckerDetails.stats.emails} />;
    const emailCurrentWeek = <EmailStats email={props.woodpeckerDetails.stats.emailsCurrentWeek} />;
    const linkUrl = "https://app.woodpecker.co/panel?u=147656#clients/" + props.woodpeckerDetails.id.toString() + "/stats";

    const csvHeaders = [
        { label: "Number", key: "number" },
        { label: "Step", key: "step" },
        { label: "Sent", key: "emailSend" },
        { label: "Delivered", key: "delivery" },
        { label: "Open", key: "open" },
        { label: "Open%", key: "open_" },
    ];
    const csvWeeklyData = props.woodpeckerDetails.stats.emailsCurrentWeek.map((item) => {
        return {
            number: item.number,
            step: item.step,
            emailSend: item.emailSend,
            delivery: item.delivery,
            open: item.open,
            open_: item.open_
        };
    });
    const csvTotalData = props.woodpeckerDetails.stats.emails.map((item) => {
        return {
            number: item.number,
            step: item.step,
            emailSend: item.emailSend,
            delivery: item.delivery,
            open: item.open,
            open_: item.open_
        };
    });
    const id = props.woodpeckerDetails.id;
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/EmailTemplate',
                state: { woodPeckerId: id, startDate: startDate, endDate: endDate}
            }}
            {...props} />
    ));
    return (
        <React.Fragment>
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={10}>
                    <Typography variant="h5">Woodpecker</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="secondary" size="small" target="_blank" href={linkUrl} fullWidth>Link</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.paper} />
                </Grid>
                <Grid item xs={12}>
                    <GeneralInfo woodpeckerDetails={props.woodpeckerDetails} />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.paper} />
                </Grid>
                <Grid item xs={12}>
                    <Stats woodpeckerDetails={props.woodpeckerDetails} />
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.paper} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">Totals</Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="secondary" size="small">
                        <CSVLink
                            data={csvTotalData}
                            headers={csvHeaders}
                            filename={`woodpecker_totals_${new Date().toLocaleDateString()}.csv`}
                            target="_blank"
                            className={classes.csvButton}
                        >Export CSV</CSVLink>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {emails}
                </Grid>
                <Grid item>
                    <Typography variant="h6">Week To Date</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    maxDate={new Date()}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" color="secondary" size="small" fullWidth>
                                <CSVLink
                                    data={csvWeeklyData}
                                    headers={csvHeaders}
                                    filename={`woodpecker_weektodate_${startDate ? startDate.toLocaleDateString() : new Date().toLocaleDateString()}_${endDate ? endDate.toLocaleDateString() : new Date().toLocaleDateString()}.csv`}
                                    target="_blank"
                                    className={classes.csvButton}
                                >Export CSV</CSVLink>
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="outlined" color="secondary" size="small" component={viewButton} fullWidth>Email</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {emailCurrentWeek}
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.paper} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}