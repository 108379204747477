import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router';
import CampaignViewModel, { UserCampaignViewModel } from '../../ViewModels/CampaignViewModel';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import LeadList from './LeadList';
import Notes from './Notes';
import Status from './Status';
import CampaignInfo from './CampaignInfo';
import Skeleton from '@mui/lab/Skeleton';
import { isNullOrUndefined } from 'util';
//import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import UserDropdown from '../../Dropdowns/UserDropdown';
import DialogContentText from '@mui/material/DialogContentText';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Divider from '@mui/material/Divider';
import WoodpeckerNoInfo from './WoodPecker/WoodpeckerNoInfo';
import Avatar from '@mui/material/Avatar';
import WoodpeckerViewModel from './WoodPecker/WoodpeckerViewModel';
import WoodpeckerInfo from './WoodPecker/WoodpeckerInfo';
import Emails from './WoodPecker/Emails';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        },
        topSpacing: {
            marginTop: theme.spacing(2)
        },
        dividerColour: {
            backgroundColor: '#f7f7f8'
        }
    })
);

interface LocationState {
    campaignId: number;
}

export default function Campaign() {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const { campaignId } = location.state || {
        campaignId: 0
    };
    const [loaded, setLoaded] = React.useState(false);
    const [campaign, setCampaign] = React.useState(new CampaignViewModel());
    const [user, setUser] = React.useState<any>(null);
    const [exec, setExec] = React.useState<UserCampaignViewModel>({ campaignId: campaignId, userId: '', startDate: new Date(), endDate: new Date() });
    const [active, setActive] = React.useState(false);
    const [openExecDialog, setDialogOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [openSurveyDialog, setSurveyDialogOpen] = React.useState(false);
    const [woodPeckerDetails, setWoodPeckerDetails] = React.useState(new WoodpeckerViewModel());
    React.useEffect(() => {

        getData();
    }, [campaignId]);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);

        fetch(`Campaign/GetCampaign?id=${campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setCampaign(data);

                const isActive = new Date(data.actualStartDate).setHours(0, 0, 0, 0) <= (new Date()).setHours(0, 0, 0, 0) && (isNullOrUndefined(data.agreementEnd) || new Date(data.agreementEnd!).setHours(0, 0, 0, 0) > (new Date()).setHours(0, 0, 0, 0));
                setActive(isActive);

                fetch(`WoodPecker/GetCampaign?id=${data.woodPeckerId}`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                })
                    .then(response => response.json())
                    .then(data => {
                        setWoodPeckerDetails(data);
                        setLoaded(true);
                    })
                    .catch(error => {
                        setError('A server error has occurred, please try again.');
                        setLoaded(true);
                    });

                fetch(`User/GetUsersNotOnCampaign?campaignId=${campaignId}`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                })
                    .then(response => response.json())
                    .then(data => {
                        setUsers(data);
                        setLoaded(true);
                    })
                    .catch(error => {
                        setError('A server error has occurred, please try again.');
                        setLoaded(true);
                    });
            })
            .catch(error => {
                console.log('Campaign error: ' + error);
                setLoaded(true);
            });
    }

    const getWoodpeckerData = async (startDate: Date | null, endDate: Date | null) => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        const url = (startDate === null || endDate === null) ? `WoodPecker/GetCampaign?id=${campaign.woodPeckerId}` : `WoodPecker/GetCampaignHistorical?id=${campaign.woodPeckerId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`

        fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setWoodPeckerDetails(data);
                setLoaded(true);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
                setLoaded(true);
            });
    }
    const updateMainExecutive = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();
        fetch('Campaign/AssignMainExecutive', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(exec)
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                setExec({ campaignId: campaignId, userId: '', startDate: new Date(), endDate: new Date() });
                setDialogOpen(false);
                getData();
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }
    const onChange = (value: number | string, name: string, client: string) => {
        setExec({
            ...exec,
            [name]: value
        });
    }
    const onClose = () => {
        if (!submitting) {
            setExec({ campaignId: campaignId, userId: '', startDate: new Date(), endDate: null });
            setDialogOpen(false);
        }
    }
    const handleSurveyClose = () => {
        setSurveyDialogOpen(false);
    };
    const emails = woodPeckerDetails.stats ? woodPeckerDetails.stats.emails.map((m, i) => <Emails key={'email_' + i} email={m} />) : [<React.Fragment />];
    return (
        <Grid container spacing={2}>
            {!loaded && <LinearProgress />}
            <Grid item xs={12} lg={3} style={{ marginTop: "5px" }}>
                <CampaignInfo campaignId={campaignId} />
                <Status campaignId={campaignId} status={campaign.status} clientId={campaign.clientId} refresh={getData} user={user} />
                <div className={classes.paper}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h6">Account Manager</Typography>
                            <Typography>
                                {loaded ? campaign.clientTeamLeader.length > 0 ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={"/User/GetAvatarFromId?id=" + campaign.clientTeamLeaderId} />
                                        </Grid>
                                        <Grid item>
                                            {campaign.clientTeamLeader}
                                        </Grid>
                                    </Grid> : 'None Assigned'
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} lg={6} className={classes.dividerColour}>
                <div className={classes.topSpacing} />
                {woodPeckerDetails.id === -1 &&
                    <CircularProgress />
                }
                {
                    woodPeckerDetails.id === 0 &&
                    <WoodpeckerNoInfo />
                }
                {woodPeckerDetails.id > 0 &&
                    <WoodpeckerInfo woodpeckerDetails={woodPeckerDetails} updateData={getWoodpeckerData} />
                }
                <div className={classes.topSpacing} />
                <Notes clientId={campaign.clientId} campaignId={campaignId} />
            </Grid>
            <Grid item xs={12} lg={3} >
                <div className={classes.topSpacing} />
                {emails}
                <br />
                <Divider className={classes.paper} />
                <LeadList campaignId={campaignId} active={active} />
            </Grid>
            <Dialog open={openExecDialog} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
                <ValidatorForm onSubmit={updateMainExecutive}>
                    <DialogTitle id="form-dialog-title">Assign Executive</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {error}
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <UserDropdown users={users} id={exec.userId} label="Executive" name="userId" onChange={onChange} required={true} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                        <Button type="submit" color="primary" disabled={submitting}>
                            Submit
                            {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <Dialog
                open={openSurveyDialog}
            >
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <Typography variant="body1">{campaign.googleSheetLink}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <CopyToClipboard text={campaign.googleSheetLink}
                                onCopy={() => { }}>
                                <IconButton size="small">
                                    <FileCopyIcon />
                                </IconButton>
                            </CopyToClipboard>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSurveyClose} color="secondary" disabled={submitting}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}