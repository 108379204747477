import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Cancel from '@mui/icons-material/Cancel';
import Save from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import green from '@mui/material/colors/green';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputBase from '@mui/material/InputBase';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: green[500],
            '&:hover': {
                color: green[700],
            },
        },
        password: {
            paddingBottom: 0
        },
    })
);

interface IEditProps {
    decorator?: string;
    info: string | number | Date | null;
    date?: boolean;
    nullable?: boolean;
    label: string;
    selectId?: string | number;
    infoList?: DropdownViewModel[];
    onSave: (newValue: string | number | Date | null) => Promise<boolean>;
    showPassword?: boolean;
    hasClipboard?: boolean;
    togglePassword?: () => void;
}

export default function InfoEdit(props: IEditProps) {
    const classes = useStyles();
    const [editing, setEditing] = React.useState(false);
    const [newInfo, setInfo] = React.useState(props.selectId ?? props.info);
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => setInfo(props.selectId ?? props.info), [editing, props.selectId, props.info]);

    const handleEdit = () => {
        var pastEdit = editing;

        setEditing(!editing);

        if (pastEdit) {
            setInfo(props.info);
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(event.target.valueAsNumber)) {
            setInfo(event.target.value);
        }
        else {
            if (props.label.includes('Renewal') && event.target.valueAsNumber > 31) setInfo(31);
            else if (props.label.includes('Renewal') && event.target.valueAsNumber < 1) setInfo(1);
            else setInfo(event.target.valueAsNumber);
        }
    }

    const save = () => {
        props.onSave(newInfo).then(() => {
            setSaving(false);
            setEditing(false);
        });
    }

    const inputProps = props.label.includes('Renewal') ? { min: 1, max: 31 } : {};

    const element = editing && props.infoList ?
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Typography variant="overline">{props.label}</Typography>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: 0 }}>
                <TextField
                    select
                    fullWidth
                    value={newInfo}
                    onChange={onChange}
                    size="small"
                >
                    {props.infoList.map(item => {
                        return <MenuItem key={item.id} value={item.id}>
                            {item.value}
                        </MenuItem>;
                    })}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <Grid container justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <IconButton size="small" onClick={save} disabled={saving}><Save className={classes.green} /></IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={handleEdit} disabled={saving}><Cancel color="error" /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        : editing ?
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography variant="overline">{props.label}</Typography>
                </Grid>
                <Grid item xs={9} style={{ paddingTop: 0 }}>
                    {props.date ?
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                showToolbar={false}
                                clearable={props.nullable}
                                mask="dd/MM/yyyy"
                                inputFormat="dd/MM/yyyy"
                                value={newInfo}
                                onChange={(newValue) => {
                                    setInfo(newValue as Date);
                                }}
                                renderInput={(params) => <TextField size="small" variant="outlined" fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                        :
                        <TextField
                            type={typeof (props.info) === "number" ? "number" : "text"}
                            inputProps={inputProps}
                            fullWidth
                            value={newInfo}
                            onChange={onChange}
                            size="small"
                        />
                    }

                </Grid>
                <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={6}>
                            <IconButton size="small" onClick={save} disabled={saving}><Save className={classes.green} /></IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton size="small" onClick={handleEdit} disabled={saving}><Cancel color="error" /></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            :
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography variant="overline">{props.label}</Typography>
                </Grid>
                <Grid item xs={7} style={{ paddingTop: 0 }}>
                    {props.showPassword !== undefined ?
                        <InputBase
                            fullWidth
                            readOnly
                            value={props.info}
                            type={props.showPassword ? "text" : "password"}
                            inputProps={{ className: classes.password }}
                        />
                        :
                        <Typography>{props.decorator}{props.date ? props.info ? new Date(props.info).toLocaleDateString("en-GB") : 'None' : props.info}</Typography>
                    }
                </Grid>
                <Grid item xs={5} style={{ paddingTop: 0 }}>
                    <Grid container justifyContent="flex-end">
                        {props.showPassword !== undefined &&
                            <Grid item>
                                <IconButton aria-label="toggle password visibility" size="small" onClick={props.togglePassword}>{props.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                            </Grid>
                        }
                        {props.hasClipboard !== undefined && props.hasClipboard &&
                            <Grid item>
                                <CopyToClipboard text={props.info as string}
                                    onCopy={() => { }}>
                                    <IconButton size="small">
                                        <FileCopyIcon />
                                    </IconButton>
                                </CopyToClipboard>
                            </Grid>
                        }
                        <Grid item>
                            <IconButton size="small" onClick={handleEdit} disabled={saving}><Edit color="secondary" /></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>;

    return (
        <React.Fragment>
            {element}
        </React.Fragment>);
}

InfoEdit.defaultProps = {
    date: false
};