import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CampaignViewModel from '../../ViewModels/CampaignViewModel';
import authService from '../../api-authorization/AuthorizeService';
import Skeleton from '@mui/material/Skeleton';
import InfoEdit from '../../Utilities/InfoEdit';
import Button from '@mui/material/Button';
import { Redirect } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        }
    })
);

interface IInfoProps {
    campaignId: number;
}

export default function CampaignInfo(props: IInfoProps) {
    const classes = useStyles();
    const [loaded, setLoaded] = React.useState(false);
    const [campaign, setCampaign] = React.useState(new CampaignViewModel());
    const [user, setUser] = React.useState<any>(null);
    const [redirectToList, setRedirect] = React.useState(false);
    const [openWarning, setOpen] = React.useState(false);
    React.useEffect(() => {
        const getData = async () => {
            setLoaded(false);
            const token = await authService.getAccessToken();
            const user = await authService.getUser();
            setUser(user);

            fetch(`Campaign/GetCampaign?id=${props.campaignId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setCampaign(data);
                    setLoaded(true);
                })
                .catch(error => {
                    console.log('Campaign error: ' + error);
                    setLoaded(true);
                });
        }
        getData();
    }, [props.campaignId]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    

    
    const updateCampaignHolderEmail = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdateCampaignHolderEmail?id=${props.campaignId}&email=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setCampaign({
                    ...campaign,
                    campaignHolderEmail: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }

    const updateGoogleSheetLink = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Campaign/UpdateGoogleSheetLink?id=${props.campaignId}&link=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setCampaign({
                    ...campaign,
                    googleSheetLink: value as string
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }

    

    const disableCampaign = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/DisableCampaign?campaignId=${props.campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (response.ok) {
                    setRedirect(true);
                }
            })
            .catch(error => {
                console.log('Update error: ' + error);
            });
    }
    const viewButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/Clients/Client',
                state: { clientId: campaign.clientId }
            }}
            {...props} />
    ));
    return (
        <React.Fragment>
            <div className={classes.paper}>
                {redirectToList && <Redirect to="/Campaigns" />}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{loaded ? campaign.name : <Skeleton />}</Typography>
                    </Grid>
                    {
                        campaign.clientPartner != null &&
                        <Grid item xs={12}>
                            {!loaded ?
                                <Skeleton />
                                :
                                <React.Fragment>
                                    <Typography>{loaded ? campaign.clientPartner : <Skeleton />}</Typography>
                                    <Typography variant="caption">Partner</Typography>
                                </React.Fragment>
                            }
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="overline">Client</Typography>
                                        <Typography>{loaded ? campaign.clientName : <Skeleton />}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" size="small" component={viewButton} >View</Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Phone</Typography>
                                <Typography>{loaded ? campaign.clientPhone : <Skeleton />}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {!loaded ?
                            <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Email</Typography>
                                <Typography>{loaded ? campaign.clientEmail : <Skeleton />}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {(user?.role !== "Executive" && user?.role !== "Creator") ?
                            loaded ? <InfoEdit info={campaign.campaignHolderEmail} label="Campaign Holder Email" onSave={updateCampaignHolderEmail} /> : <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Campaign Holder Email</Typography>
                                <Typography>{loaded ? campaign.campaignHolderEmail : <Skeleton />}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {(user?.role !== "Executive" && user?.role !== "Creator") ?
                            loaded ? <InfoEdit info={campaign.googleSheetLink} label="Google Sheet Link" onSave={updateGoogleSheetLink} /> : <Skeleton />
                            :
                            <React.Fragment>
                                <Typography variant="overline">Google Sheet Link</Typography>
                                <Typography>{loaded ? campaign.googleSheetLink : <Skeleton />}</Typography>
                            </React.Fragment>
                        }
                    </Grid>
                    {(user?.role !== "Executive" && user?.role !== "Creator") &&
                        <Grid item xs={12}>
                            <Button fullWidth variant="outlined" color="secondary" onClick={handleClickOpen} disabled={!loaded}>Archive Campaign</Button>
                        </Grid>
                    }
                </Grid>
                <Dialog
                    open={openWarning}
                    onClose={handleClose}
                    aria-labelledby="warning-title"
                    aria-describedby="warning-description"
                >
                    <DialogTitle id="warning-title">{"Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="warning-description">
                            This will permanently archive the campaign.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                    </Button>
                        <Button onClick={disableCampaign} color="primary" autoFocus>
                            Archive
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Divider className={classes.paper} />
        </React.Fragment>
    );
}