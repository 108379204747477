import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CampaignTableRow from './CampaignTableRow';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import DenseTableCell from '../../Utilities/DenseTableCell';
import { CampaignListViewModel } from '../../ViewModels/CampaignViewModel';
function total(items: CampaignListViewModel[]) {
    return items.map(({ emails }) => emails).reduce((sum, i) => sum + i, 0);
}
interface ICampaignTableProps {
    campaigns: CampaignListViewModel[];
    user:any;
}

export default function CampaignTable(props: ICampaignTableProps) {
    const items = props.campaigns.map((item, index) =>
        <CampaignTableRow key={index} campaign={item} user={ props.user} />
    );

    return (
        <ResponsiveTable aria-label="campaign list" style={{padding: 8}}>
            <TableHead>
                <TableRow>
                    <DenseTableCell>Campaigns</DenseTableCell>    
                    <DenseTableCell>Status</DenseTableCell>
                    <DenseTableCell>Emails</DenseTableCell>
                    <DenseTableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {items}
                <TableRow>
                    <DenseTableCell />
                    <DenseTableCell />
                    <DenseTableCell >{total(props.campaigns)}</DenseTableCell>
                    <DenseTableCell />
                </TableRow>
            </TableBody>
        </ResponsiveTable>
    );
}