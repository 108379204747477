import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import RegisterConfirmation from './components/Admin/RegisterConfirmation';
import ClientList from './components/Clients/ClientList';
import TeamList from './components/Teams/TeamList';
import Campaign from './components/Campaigns/CampaignPage/Campaign';
import UpdateUser from './components/Admin/UpdateUser';
import AdminDashboard from './components/Admin/AdminDashboard';
import PowerBI from './components/PowerBITest/PowerBI';
import Client from './components/Clients/ClientPage/Client';
import CustomerSatisfactionSurvey from './components/CustomerSatisfactionSurvey/CustomerSatisfactionSurvey';
import Profile from './components/Profile/Profile';
import SuperAdminPowerBI from './components/PowerBITest/SuperAdminPowerBI';
import EmailReportContainer from './components/Reports/Woodpecker/EmailReportContainer';
import CreateClient from './components/Clients/CreateClient';
import StatEmailTemplate from './components/EmailTemplate/StatEmailTemplate';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const theme = createTheme(adaptV4Theme({
    palette: {
        primary: { main: /*'#0077B5'*/ '#000000' },
        secondary: {
            main: /*'#FF7052'*/'#000000',
            contrastText: '#ffffff'
        },
        background: {default: '#ffffff'}
    }
}));

export default function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
                <Layout>
                    <Switch>
                        <AuthorizeRoute exact path='/' component={Home} />
                        <AuthorizeRoute exact path='/Clients' component={ClientList} />
                        <AuthorizeRoute exact path='/Clients/Client' component={Client} />
                        <AuthorizeRoute exact path='/Clients/Create' component={CreateClient} />
                        {/*<AuthorizeRoute exact path='/Campaigns' component={CampaignList} />*/}
                        <AuthorizeRoute exact path='/Campaigns/Campaign' component={Campaign} />
                        {/*<AuthorizeRoute exact path='/Campaigns/Create' component={CreateCampaign} />*/}
                        <AuthorizeRoute exact path='/Admin' component={AdminDashboard} />
                        <AuthorizeRoute exact path='/Admin/EditUser' component={UpdateUser} />
                        <AuthorizeRoute exact path='/PowerBI' component={PowerBI} />
                        <AuthorizeRoute exact path='/Teams' component={TeamList} />
                        <AuthorizeRoute exact path='/SuperAdminPowerBI' component={SuperAdminPowerBI} />
                        <AuthorizeRoute exact path='/Profile' component={Profile} />
                        <AuthorizeRoute exact path='/EmailReports' component={EmailReportContainer} />
                        <Route path='/RegisterConfirmation/:userId/:code' component={RegisterConfirmation} />
                        <Route path='/CustomerSatisfactionSurvey/:surveyId' component={CustomerSatisfactionSurvey} />
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} ><ApiAuthorizationRoutes /></Route>
                        <AuthorizeRoute exact path='/EmailTemplate' component={StatEmailTemplate} />
                    </Switch>
                </Layout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
