import React, { useRef, useState } from "react";
import EmailStats from "./EmailStats";
import LeadEmailTable from "./LeadEmailTable";
import { useLocation } from 'react-router';
import authService from "../api-authorization/AuthorizeService";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EmailStatsViewModel from "./ViewModels/EmailStatsViewModel";
import CircularProgress from "@mui/material/CircularProgress";
interface LocationState {
    woodPeckerId: number;
    startDate: Date;
    endDate: Date;
}

interface IStatEmailTemplateProps {
}

export default function StatEmailTemplate(props: IStatEmailTemplateProps) {
    const [emailStatsViewModel, setEmailStatsViewModel] = useState(new EmailStatsViewModel());
    const toCopy = useRef<HTMLDivElement>(null);
    const location = useLocation<LocationState>();
    const [loaded, setLoaded] = useState(false);
    const { woodPeckerId, startDate, endDate } = location.state || {
        woodPeckerId: 0,
        startDate: null,
        endDate: null
    };
    React.useEffect(() => {
        getWoodpeckerData();
    }, []);
    const getWoodpeckerData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        const url = (startDate === null || endDate === null) ? `WoodPecker/GetWoodpeckerEmailTemplate?woodpeckerId=${woodPeckerId}` : `WoodPecker/GetWoodpeckerEmailTemplateHistorical?woodpeckerId=${woodPeckerId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`

        fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setEmailStatsViewModel(data);
                setLoaded(true);
            })
            .catch(error => {
                setLoaded(true);
            });
    }
    const copy = () => {
        if (toCopy && toCopy.current) {
            console.log("Copying Content");
            //const code = toCopy.current.innerHTML;
            //copyFormatted(code);
            window.getSelection()!.removeAllRanges();
            var range = document.createRange();
            range.selectNode(toCopy.current);
            window.getSelection()!.addRange(range);
            document.execCommand('copy');
            window.getSelection()!.removeAllRanges();
        }
    }
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                    <Button variant="outlined" color="secondary" size="small" onClick={copy} fullWidth disabled={ !loaded}>Copy</Button>
                </Grid>
                {
                    loaded ?
                    <Grid item xs={12} ref={toCopy}>
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <EmailStats woodpeckerVM={emailStatsViewModel.woodpeckerViewModel} />
                            </Grid>
                            <Grid item xs={12}>
                                <LeadEmailTable leadEmailVM={emailStatsViewModel.interestedLeadEmailViewModel} interestedTable={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <LeadEmailTable leadEmailVM={emailStatsViewModel.maybeLeadEmailViewModel} interestedTable={false} />
                            </Grid>
                        </Grid>
                        </Grid>
                        :
                        <CircularProgress />
                }
            </Grid>
        </React.Fragment>
    );
}