// import * as React from 'react';
//import { Theme } from '@mui/material/styles';
//import createStyles from '@mui/styles/createStyles';
//import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import WoodpeckerViewModel from './WoodpeckerViewModel';
import Grid from '@mui/material/Grid';

//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//            marginBottom: theme.spacing(2),
//            minHeight: '300px',
//            display: "flex",
//            flexDirection: "column",
//            justifyContent: "center"
//        },
//        notes: {
//            whiteSpace: 'pre-wrap'
//        }
//    })
//);

interface IGeneralInfoProps {
    woodpeckerDetails: WoodpeckerViewModel;
}

export default function GeneralInfo(props: IGeneralInfoProps) {
    // const classes = useStyles();
    // const { user, clientNote, clientId } = props;

   
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography variant="body1">From Name: {props.woodpeckerDetails.from_name}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1">From Email: {props.woodpeckerDetails.from_email}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1">Status: {props.woodpeckerDetails.status}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1">Per Day: {props.woodpeckerDetails.per_day}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1">Timezone: {props.woodpeckerDetails.stats.emails.length > 0 ? props.woodpeckerDetails.stats.emails[0].timezone : "Not Found"}</Typography>
            </Grid>
        </Grid>
    );
}