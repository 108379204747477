import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UserDropdown from '../Dropdowns/UserDropdown';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import Alert from '../Utilities/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DropdownViewModel from '../ViewModels/DropdownViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    }),
);

export default function ManageUsers() {
    const classes = useStyles();
    const [user, setUser] = React.useState('');
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [showAlert, setShow] = React.useState(false);
    const [alert, setAlert] = React.useState(<React.Fragment />);
    const [updating, setUpdating] = React.useState(false);
    React.useEffect(() => { getData(); }, []);

    const handleChange = (value: number | string, name: string, client: string) => {
        setUser(value as string);
    }

    const updateLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Admin/EditUser',
                state: { userId: user }
            }}
            {...props} />
    ));

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const openAlert = (message: string, error: boolean) => {
        setAlert(
            <Alert onClose={handleClose} severity={error ? "error" : "success"}>
                {message}
            </Alert>
        );
        setShow(true);
    }

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('User/GetUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Users get error: ' + error);
            });
    }

    const removeUser = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        fetch(`User/RemoveUser?id=${user}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("User removed successfully", false);
                    setUpdating(false);
                    getData();
                } else {
                    openAlert("Failed to remove user, please try again.", true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                openAlert("Failed to remove user, please try again.", true);
                setUpdating(false);
            });
    }

    const enableUser = async () => {
        const token = await authService.getAccessToken();
        setUpdating(true);

        fetch(`User/EnableUser?id=${user}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("User enabled successfully", false);
                    setUpdating(false);
                    getData();
                } else {
                    openAlert("Failed to enable user, please try again.", true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                openAlert("Failed to enable user, please try again.", true);
                setUpdating(false);
            });
    }

    const disableButton = user.length > 0 && users.find(f => f.id === user)?.disabled === true ?
        <Button fullWidth variant="contained" color="secondary" onClick={enableUser} disabled={user.length <= 0 || updating}>
            Enable User
            {updating && <CircularProgress size={20} className={classes.buttonProgress} />}
        </Button>
        :
        <Button fullWidth variant="contained" color="secondary" onClick={removeUser} disabled={user.length <= 0 || updating}>
            Suspend User
            {updating && <CircularProgress size={20} className={classes.buttonProgress} />}
        </Button>;

    return (
        <Paper className={classes.paper}>
            <Typography variant="h2">Manage Users</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UserDropdown
                        users={users}
                        label="User"
                        name="user"
                        id={user}
                        onChange={handleChange}
                        required={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {disableButton}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button fullWidth variant="contained" color="primary" component={updateLink} disabled={user.length <= 0 || updating}>Update User</Button>
                </Grid>
            </Grid>
            {showAlert &&
                <Grid item xs={12}>
                    {alert}
                </Grid>
            }
        </Paper>
    );
}