import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { NoteViewModel } from '../../ViewModels/CampaignViewModel';
import authService from '../../api-authorization/AuthorizeService';
import Divider from '@mui/material/Divider';
import CreateNote from './CreateNote';
import Button from '@mui/material/Button';
import Flag from '@mui/icons-material/Flag';
import Grow from '@mui/material/Grow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        notes: {
            whiteSpace: 'pre-wrap'
        }
    })
);

interface IListProps {
    clientId: number;
}

export default function Notes(props: IListProps) {
    const classes = useStyles();
    const [notes, setNotes] = React.useState<NoteViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);
    const [openDialog, setDialog] = React.useState(false);

    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Client/GetNotes?clientId=${props.clientId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setNotes(data);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Note list error: ' + error);
                setLoaded(true);
            });
    }, [props.clientId]);
    React.useEffect(() => {
        if (props.clientId > 0) {
            getData();
        }
    }, [getData, props.clientId]);

    const items = notes.map((note, index) => {
        return (
            <Paper key={'note' + index} className={classes.paper}>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">
                            {note.client &&
                                <Flag color="secondary" />
                            }
                            {note.type}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">{new Date(note.timestamp).toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">Created By: {note.author}</Typography>
                    </Grid>
                    {
                        note.subType!=null  &&
                        <Grid item xs={12}>
                            <Typography className={classes.notes}>{note.subType}</Typography>
                        </Grid>
                    }
                    {
                        note.applicationContent!=null &&
                        <Grid item xs={12}>
                            <Typography className={classes.notes}>{note.applicationContent}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography className={classes.notes}>{note.content}</Typography>
                    </Grid>
                </Grid>
            </Paper >
        );
    });

    const handleDialog = () => {
        setDialog(!openDialog);
    }

    const closeDialog = (refresh: boolean) => {
        setDialog(false);

        if (refresh) {
            getData();
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">Notes</Typography>
                </Grid>
                <Grow in={loaded}
                    {...(loaded ? { timeout: 1000 } : {})}>
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={handleDialog}>Add Note</Button>
                    </Grid>
                </Grow>
            </Grid>
            <Grow in={loaded}
                {...(loaded ? { timeout: 1000 } : {})}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {items}
                    </Grid>
                </Grid>
            </Grow>
            <CreateNote clientId={props.clientId} open={openDialog} close={closeDialog} />
        </React.Fragment>
    );
}