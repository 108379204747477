import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import ClientViewModel from '../../ViewModels/ClientViewModel';
import Notes from './Notes';
import ClientInfo from './ClientInfo';
import CampaignTable from './CampaignTable';
import MultiFileInput from './MultiFileInput';
import FileDataViewModel from '../../ViewModels/FileDataViewModel';
import { CreateFileViewModel } from '../../ViewModels/CreateFileViewModel';
import { UtilityMethods } from './UtilityMethods';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import TeamLeaderDialog from '../TeamLeaderDialog';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import Messages from './Messages';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Status from './Status';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8,
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface LocationState {
    clientId: number;
}

export default function Client() {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const { clientId } = location.state || {
        ClientId: 0
    };
    const [loaded, setLoaded] = React.useState(false);
    const [user, setUser] = React.useState<any>(null);
    const [openUploadDialog, setUploadOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [client, setClient] = React.useState(new ClientViewModel());
    const [fileData, setFileData] = React.useState<any>(null);
    const [files, setFiles] = React.useState<any>(null);
    const [fileList, setFileList] = React.useState<string[]>([]);
    const [filesUpdating, setFilesUpdating] = React.useState(false);
    const [openExecDialog, setDialogOpen] = React.useState(false);
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => {
        getFiles();
        getData();
    }, [clientId]);
    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);

        fetch(`Client/GetClient?clientId=${clientId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setClient(data);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Client error: ' + error);
                setLoaded(true);
            });
        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
        
    }
    const getFiles = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);

        fetch(`Client/GetFileList?clientId=${clientId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setFileList(data);
                setFilesUpdating(false);
            })
            .catch(error => {
                console.log('File error: ' + error);
            });
    }
    const submit = (fileData: FileDataViewModel[], file: File[]) => {
        let formdata: FormData = new FormData();
        setSubmitting(true);
        setError('');
        var now = new Date();
        setFilesUpdating(true);
        var files: CreateFileViewModel[] = [];
        for (var i = 0; i < file.length; i++) {
            let createFileViewModel = new CreateFileViewModel;
            createFileViewModel.file = file[i];
            createFileViewModel.name = fileData[i].fileName;
            createFileViewModel.dateAdded = now;
            createFileViewModel.clientId = clientId;
            files.push(createFileViewModel);
        }
        formdata = UtilityMethods.convertModelToFormData(files, formdata);

        fetch('Client/UploadFiles', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            body: formdata
        })
            .then((response: Response) => response.json())
            .then((data: any) => {
                setSubmitting(false);
                setUploadOpen(false);
                getFiles();
            })
            .catch(error => {
                setSubmitting(false);
                setError('An error occured');
            });
    }
    const removeFile = (fileData2: FileDataViewModel,) => {
        let f = files;
        let filesData = fileData;
        const dataindex = filesData.indexOf(fileData2);
        if (dataindex > -1) {
            filesData.splice(dataindex, 1);
            f.splice(dataindex, 1);
        }
        setFiles(f);
        setFileData(filesData);
    }
    const openUploaderDialog = () => {
        setUploadOpen(true);
    }
    const openTeamLeaderDialog = () => {
        setDialogOpen(true);
    }
    const onClose = (refresh: boolean) => {
        if (!submitting) {
            //setExec({ ClientId: ClientId, userId: '', startDate: new Date(), endDate: null });
            setUploadOpen(false);
            setDialogOpen(false);
            if (refresh) {
                getData();
            }
        }
    }
    const deleteFile = async (m: string) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);
        setFilesUpdating(true);
        fetch(`Client/DeleteFile?fileId=${m}&clientId=${clientId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                getFiles();
            })
            .catch(error => {
                getFiles();
                console.log('File error: ' + error);
            });
    }
    const filesList = fileList.map(m =>
        <React.Fragment>
            <Grid container alignItems="center">
                <Grid item xs={8} style={{
                    display: "block",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                    <Tooltip title={m}><Typography variant="body1">{m}</Typography></Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Grid container>
                        <Grid item xs={4}>
                            <a href={`Client/GetFilePreview?fileId=${encodeURIComponent(m)}&clientId=${clientId}`} target="_blank">
                            <IconButton
                                color="primary"
                                aria-label="view"
                                size="small"
                                //onClick={()=>download(m)}
                                disabled={
                                    !m.endsWith(".jpg") &&
                                    !m.endsWith(".pdf") &&
                                    !m.endsWith(".weba") &&
                                    !m.endsWith(".webm") &&
                                    !m.endsWith(".webp") &&
                                    !m.endsWith(".wav") &&
                                    !m.endsWith(".txt") &&
                                    !m.endsWith(".tif") &&
                                    !m.endsWith(".tiff") &&
                                    !m.endsWith(".png") &&
                                    !m.endsWith(".mpeg") &&
                                    !m.endsWith(".mp3") &&
                                    !m.endsWith(".jpeg") &&
                                    !m.endsWith(".gif") &&
                                    !m.endsWith(".docx") &&
                                    !m.endsWith(".doc") &&
                                    !m.endsWith(".avi") &&
                                    !m.endsWith(".bmp") &&
                                    !m.endsWith(".svg")
                                }
                            >
                                <VisibilityIcon />
                                </IconButton>
                            </a>
                        </Grid>
                        <Grid item xs={4}>
                            <a href={`Client/GetFileFromBlob?fileId=${encodeURIComponent(m)}&clientId=${clientId}`} target="_blank">
                            <IconButton
                                color="primary"
                                aria-label="download"
                                component="span"
                                size="small"
                                >
                                <CloudDownloadIcon />
                                </IconButton>
                                </a>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButton color="primary" aria-label="delete" component="span" size="small" onClick={()=>deleteFile(m)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
    return (
        <Grid container spacing={2}>
            {!loaded && <LinearProgress />}
            <Grid item xs={12} lg={12} style={{ marginTop: "5px" }}>
                <Typography variant="h2">{loaded ? client.clientName : <Skeleton />}</Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
                <ClientInfo clientId={clientId} />
                <Status campaignId={clientId} status={client.status} clientId={clientId} refresh={getData} user={user} />
                <Paper className={classes.paper}>
                    <Typography variant="h6">Account Manager</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography>
                                {loaded ? (((client.teamLeader!=null && client.teamLeader.length > 0) ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={"/User/GetAvatarFromId?id=" + client.teamLeaderId}>
                                                {client.teamLeader.substring(0, 2)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            {client.teamLeader}
                                        </Grid>
                                    </Grid> : 'None Assigned'))
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                        <Grid item>
                            {((user?.role !== "Executive" && user?.role !== "Creator") &&
                                <IconButton size="small" onClick={openTeamLeaderDialog} style={{ marginLeft: 8 }}>
                                    <Edit color="secondary" />
                                </IconButton>)
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper className={classes.paper}>
                    <CampaignTable user={ user} campaigns={client.campaigns} />
                </Paper>
                <Notes clientId={client.id} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Paper className={classes.paper}>
                    {loaded ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Files</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {filesUpdating ? <CircularProgress style={{ marginLeft: '50%' }}/>: filesList}
                            </Grid>
                            <Grid item xs={12}>
                                <Button color="secondary" variant="outlined" fullWidth size="small" onClick={openUploaderDialog}>Upload</Button>
                            </Grid>
                        </Grid>
                        : <Skeleton height={100} />
                    }
                </Paper>
                    {loaded ?
                        <Messages clientId={clientId} />
                        : <Skeleton height={100} />
                    }
            </Grid>
            <Dialog open={openUploadDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload Files</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MultiFileInput
                                fileData={fileData}
                                files={files}
                                addFile={submit}
                                removeFile={removeFile}
                                submitting={submitting}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>onClose(false)} fullWidth color="secondary" disabled={submitting}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <TeamLeaderDialog users={users} clientId={clientId} open={openExecDialog} close={onClose} />
        </Grid>
    );
}