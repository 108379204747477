import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
// import Typography from '@mui/material/Typography';

// Consider deleting or researching how to use

export default function SuperAdminPowerBI() {

    const [Access, SetAccess] = React.useState(false);
    React.useEffect(() => {
        authService.getUser().then(user => {
            if (user.role == "SuperAdmin") {

                SetAccess(true);
            }
        });
    }, []);



    return (
        <React.Fragment>
            {
                Access &&
                <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden' }}>
                    <iframe style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0, height: 'calc(100vh - 100px)' }} src="https://app.powerbi.com/reportEmbed?reportId=8e74014f-d0fe-4c53-84d9-bb2117eb0604&autoAuth=true&ctid=59c5c60f-956b-4e71-b50d-48597a37911c&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" ></iframe>
                </div>
            }
        </React.Fragment>
    );
}