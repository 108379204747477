import * as React from 'react';
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import ReportViewModel, { SortReportViewModel } from "../../ViewModels/ReportViewModel";
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Datepicker from '../../Utilities/DatePicker';
import EmailReportTableRow from './EmailReportTableRow';
import TableBody from '@mui/material/TableBody';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import DropdownViewModel from '../../ViewModels/CampaignDropdownViewModel';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment';
import Table from '@mui/material/Table';
import ClientFilterDropdown from '../../Dropdowns/ClientFilterDropdown';
import Button from '@mui/material/Button';
import { CSVLink } from 'react-csv';

interface IProps {
    tab: number
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof SortReportViewModel;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'campaignName', numeric: false, disablePadding: true, label: 'Campaign' },
    { id: 'clientName', numeric: false, disablePadding: true, label: 'Client' },
    { id: 'country', numeric: false, disablePadding: true, label: 'Country' },
    { id: 'packageName', numeric: false, disablePadding: true, label: 'Package' },
    { id: 'earliestRecord', numeric: true, disablePadding: true, label: 'EarliestRecord' },
    { id: 'latestRecord', numeric: true, disablePadding: true, label: 'Latest Record' },
    { id: 'sent', numeric: true, disablePadding: true, label: 'Sent' },
    { id: 'opened', numeric: true, disablePadding: true, label: 'Opened' },
    { id: 'clicked', numeric: true, disablePadding: true, label: 'Clicked' },
    { id: 'interested', numeric: true, disablePadding: true, label: 'Interested' },

];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        scrollTable: {
            display: "block",
            overflowX: "auto",
            maxHeight: "66vh"
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        csvButton: {
        color: 'inherit',
        textDecoration: 'inherit'
    }
    })
);

type Order = 'asc' | 'desc';

export default function EmailReportTable(props: IProps) {
    const classes = useStyles();
    const { tab } = props;
    const [orderBy, setOrderBy] = React.useState('');
    const [order, setOrder] = React.useState<Order>('desc');
    const d = new Date();
    d.setDate(d.getDate() - 7);
    const [date, setDate] = React.useState(d);
    const [endDate, setEndDate] = React.useState(new Date());
    const today = new Date();
    today.setDate(1);
    const [month, setMonth] = React.useState(today);
    const [data, setData] = React.useState<ReportViewModel[] | null>(null);
    const [loaded, setLoaded] = React.useState(false);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);
    const [clientFilter, setClientFilter] = React.useState(-1);

    React.useEffect(() => {
        getData();
    }, []);
    React.useEffect(() => {
        onTabChange();
    }, [tab]);
    React.useEffect(() => {
        if (data != null) {
            getData();
        }
    }, [month, date, endDate, order, orderBy, clientFilter]);

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        var searchstartdate = moment(date).startOf('day');
        var searchenddate = moment(endDate).startOf('day');

        if (tab == 0) {
            searchstartdate = searchstartdate.startOf('week');
            searchenddate = searchstartdate.clone().add(6, 'd');
        }
        if (tab === 1) {
            searchstartdate = searchstartdate.startOf('month');
            searchenddate = searchstartdate.clone().endOf('month');
        }
        let url = `Report/GetWoodpeckerReport?clientFilter=${clientFilter}&startDate=${searchstartdate.toISOString()}&endDate=${searchenddate.toISOString()}&sortBy=${orderBy}&sort=${order}`;

        fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token} ` }
        })
            .then(response => response.json())
            .then(data => {
                setData(data);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Woodpecker report error: ' + error);
                setLoaded(true);
            });

        fetch(`Report/GetClientDropdown`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token} ` }
        })
            .then(response => response.json())
            .then(data => {
                setClients(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SortReportViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: keyof SortReportViewModel) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };
    const onTabChange = () => {
        if (tab === 0) {
            setDate(moment(date).startOf('week').toDate());
            setEndDate(moment(date).endOf('week').toDate());
        }
        else if (tab === 1) {
            setDate(moment(date).startOf('month').toDate());
            setEndDate(moment(date).endOf('month').toDate());
            setMonth(moment(date).startOf('month').toDate());
        }
    }
    const onDateChange = (date: Date | null, name: string) => {
        if (tab === 1) {
            if (date !== null) {
                setDate(date as Date);
                setEndDate(moment(date).endOf('month').toDate());
            }
        } else {
            if (name.includes("end")) {
                if (tab === 0) {
                    setEndDate(moment(date).endOf('week').toDate());
                } else {
                    setEndDate(date as Date);
                }
            } else {
                if (tab === 0) {
                    setDate(moment(date).startOf('week').toDate());
                } else {
                    setDate(date as Date);
                }
            }
        }
    }

    const datepicker = tab === 1 ?
        <Datepicker
            monthOnly={true}
            name="month"
            label="Month"
            date={date}
            onChange={onDateChange}
            disableFuture={true}
            disablePast={false}
        /> :
        <Datepicker
            date={date}
            name="date"
            label="Start Date"
            disableFuture={true}
            disablePast={false}
            onChange={onDateChange}
        />;

    
    const onClientChange = (clientId: number) => {
        setClientFilter(clientId);
    }
    const csvHeaders = [
        { label: 'Campaign', key: "campaign"},
        { label: 'Client', key: "client"},
        { label: 'Country', key: "country"},
        { label: 'Package', key: "package"},
        { label: 'EarliestRecord', key: "earliestRecord"},
        { label: 'Latest Record', key: "latestRecord"},
        { label: 'Sent', key: "sent"},
        { label: 'Opened', key: "opened"},
        { label: 'clicked', key: "clicked"},
{ label: 'interested', key: "interested"},
    ];
    const csvData = data != null ? data.map((item) => {
        return {
            campaign: item.campaignName,
            client: item.clientName,
            country: item.country,
            package: item.packageName,
            earliestRecord: item.earliestRecord,
            latestRecord: item.latestRecord,
            sent: item.sent,
            opened: item.opened,
            clicked: item.clicked,
            interested: item.interested,
        };
    }):[];
    return (
        <React.Fragment>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={2}>
                    {datepicker}
                </Grid>
                {tab === 2 &&
                    <Grid item xs={2}>
                        <Datepicker
                            date={endDate}
                            name="endDate"
                            label="End Date"
                            disableFuture={true}
                            disablePast={false}
                            onChange={onDateChange}
                        />
                    </Grid>
                }
                <Grid item xs={2}>
                    <ClientFilterDropdown client={clientFilter} clients={clients} onChange={onClientChange} />
                    
                </Grid>

                <Grid item>
                    <Button variant="outlined" color="secondary" size="small">
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={`woodpecker_report}.csv`}
                            target="_blank"
                            className={classes.csvButton}
                        >Export CSV</CSVLink>
                    </Button>
                </Grid>
            </Grid>
            <TableContainer className={classes.scrollTable}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="report table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell: HeadCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loaded &&
                            <TableRow style={{ height: 53 }}>
                                <TableCell colSpan={20} align="center"><CircularProgress color="secondary" /><Typography>Pulling latest data</Typography></TableCell>
                            </TableRow>
                        }
                        {loaded && data && data.length > 0 &&
                            data.map((row: ReportViewModel) => {
                                return (<EmailReportTableRow row={row} />);
                            })
                        }
                        {loaded && data && data.length <= 0 &&
                            <TableRow key={-1}>
                                <TableCell colSpan={20} align="center"><Typography>No emails found</Typography></TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}