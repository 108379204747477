//import * as React from 'react';
//import { Theme } from '@mui/material/styles';
//import createStyles from '@mui/styles/createStyles';
//import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Email } from './WoodpeckerViewModel';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//            marginBottom: theme.spacing(2),
//        },
//        notes: {
//            whiteSpace: 'pre-wrap'
//        }
//    })
//);

interface IEmailsProps {
    email: Email;
}

export default function Emails(props: IEmailsProps) {
    // const classes = useStyles();
    // const { user, clientNote, clientId } = props;


    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body1">Subject: {props.email.subject}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">Number: {props.email.number}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">Step: {props.email.step}</Typography>
                    </Grid>
                   
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>                   
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="content" dangerouslySetInnerHTML={{ __html: props.email.msg }}></div>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}