import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateNoteViewModel } from '../../ViewModels/CampaignViewModel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import authService from '../../api-authorization/AuthorizeService';
import NoteTypeDropdown from '../../Dropdowns/NoteTypeDropdown';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    }),
);

interface ICreateProps {
    clientId: number;
    campaignId: number;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function CreateNote(props: ICreateProps) {
    const classes = useStyles();
    const [note, setNote] = React.useState(new CreateNoteViewModel(props.campaignId));
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');
    const [clientNote, setClientNote] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote({
            ...note,
            [event.target.name]: event.target.value
        });
    }

    const handleClientToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientNote(event.target.checked);
    }

    const onClose = () => {
        if (!saving) {
            setNote(new CreateNoteViewModel(props.campaignId));
            props.close(false);
        }
    }

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        if (clientNote) {
            const newNote = note;
            note.id = props.clientId;

            fetch('Client/CreateNote', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify(newNote)
            })
                .then(response => response.text())
                .then(data => {
                    setSaving(false);
                    props.close(true);
                    setNote(new CreateNoteViewModel(props.campaignId));
                })
                .catch(error => {
                    setSaving(false);
                    setError('A server error has occurred, please try again.');
                });

        } else {
            fetch('Campaign/CreateNote', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify(note)
            })
                .then(response => response.text())
                .then(data => {
                    setSaving(false);
                    props.close(true);
                    setNote(new CreateNoteViewModel(props.campaignId));
                })
                .catch(error => {
                    setSaving(false);
                    setError('A server error has occurred, please try again.');
                });
        }
    }

    return (
        <Dialog open={props.open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle>
                <Typography className={classes.heading}>Add Note</Typography>
            </DialogTitle>
            <ValidatorForm onSubmit={submit}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<Switch checked={clientNote} onChange={handleClientToggle}/>}
                                label="Client Note"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NoteTypeDropdown id={note.noteTypeId} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label="Note"
                                name="content"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                multiline
                                rows={4}
                                value={note.content}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={saving}>Cancel</Button>
                    <Button type="submit" color="primary" disabled={saving}>
                        Save
                        {saving && <CircularProgress color='secondary' size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}