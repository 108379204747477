import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import amber from '@mui/material/colors/amber';
import grey from '@mui/material/colors/grey';
import CreateStatusNote from './CreateStatusNote';
import Divider from '@mui/material/Divider';
import blue from '@mui/material/colors/blue';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        statusGrid: {
            flexGrow: 1
        },
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
            width: '100%'
        },
        blue: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500],
            '&:hover': {
                backgroundColor: blue[700],
            },
            width: '100%'
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
            '&:hover': {
                backgroundColor: amber[700],
            },
            width: '100%'
        },
        lightRed: {
            color: theme.palette.getContrastText('#ff7961'),
            backgroundColor: '#ff7961',
            '&:hover': {
                backgroundColor: '#ff7961',
            },
            width: '100%'
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
            width: '100%'
        },
        darkRed: {
            color: theme.palette.getContrastText(red[700]),
            backgroundColor: red[700],
            '&:hover': {
                backgroundColor: red[900],
            },
            width: '100%'
        },
        grey: {
            color: theme.palette.getContrastText(grey[500]),
            backgroundColor: grey[500],
            width: '100%'
        },
        default: {
            width: '100%'
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IListItemProps {
    status: string;
    onClick: (value: string) => void;
}

const StatusListItem = React.forwardRef((props: IListItemProps, ref: any) => {
    const onChange = () => {
        props.onClick(props.status);
    }

    return (
        <MenuItem onClick={onChange} ref={ref}>
            {props.status}
        </MenuItem>
    );
});

interface IStatusProps {
    campaignId: number;
    status: string;
    clientId: number;
    refresh: () => void;
    user: any;
}

export default function Status(props: IStatusProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [newStatus, setNew] = React.useState(props.status);
    const [user, setUser] = React.useState<any>(null);
    //const [saving, setSaving] = React.useState(false);
    React.useEffect(() => { setNew(props.status); }, [props.status]);
    React.useEffect(() => { setUser(props.user); }, [props.user]);
    const [openDialog, setDialog] = React.useState(false);
    const adminStatuses = [
        { status: "Live", class: classes.green },
        { status: "Pending", class: classes.amber },
        { status: "Notice", class: classes.lightRed },
        { status: "Cancelled", class: classes.red },
        { status: "Onboarding", class: classes.default },
        { status: "Complete", class: classes.default },
        { status: "Paused", class: classes.grey },
        { status: "Confirmed Cancelling", class: classes.red },
        { status: "DRAFT", class: classes.blue },
        { status: "RUNNING", class: classes.green },
        { status: "EDITED", class: classes.green },
        { status: "COMPLETED", class: classes.default },
        { status: "PAUSED", class: classes.amber },
        { status: "DELETED", class: classes.red },
        { status: "Unknown", class: classes.red },
        { status: "STOPPED", class: classes.red },
    ];
    const statuses = [
        { status: "Live", class: classes.green },

    ];

    const onChange = async (value: string) => {
        setNew(value);
        //setSaving(true);
        handleClose();
        handleDialog();
        //const token = await authService.getAccessToken();

        //fetch(`Campaign/UpdateStatus?campaignId=${props.campaignId}&status=${value}`, {
        //    method: 'POST',
        //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //})
        //    .then(response => response.text())
        //    .then(data => {
        //        setSaving(false);
        //    })
        //    .catch(error => {
        //        setSaving(false);
        //    });
    }

    //const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    //        setAnchorEl(event.currentTarget);        
    //}

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDialog = () => {
        setDialog(!openDialog);
    }

    const closeDialog = (refresh: boolean) => {
        setDialog(false);

        if (!refresh) {
            setNew(props.status);
        } else {
            props.refresh();
        }
    }
    const statusClass = newStatus.length > 0 ? adminStatuses.find(f => f.status === newStatus)!.class : classes.default;
    const list = statuses.map((status) => (
        <StatusListItem key={status.status} status={status.status} onClick={onChange} />
    ));
    const adminList = adminStatuses.map((status) => (
        <StatusListItem key={status.status} status={status.status} onClick={onChange} />
    ));
    return (
        <React.Fragment>
            <div className={classes.paper}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Status</Typography>
                    </Grid>
                    <Grid item className={classes.statusGrid}>
                        <Fab className={statusClass} variant='extended' /*onClick={openMenu}*/>
                            {newStatus}
                            {/*saving && <CircularProgress color='secondary' size={24} className={classes.buttonProgress} />*/}
                        </Fab>
                    </Grid>
                </Grid>
            </div>
            <Divider className={classes.paper}/>
            <Menu onClose={handleClose} anchorEl={anchorEl} open={Boolean(anchorEl)}>
                {
                    (user?.role === "Admin" || user?.role === "SuperAdmin") ?
                        adminList :
                        list
                }
            </Menu>
            <CreateStatusNote clientId={props.clientId} campaignId={props.campaignId} open={openDialog} close={closeDialog} from={props.status} to={newStatus} />
        </React.Fragment>
    );
}