import * as React from 'react';
//import { Theme } from '@mui/material/styles';
//import createStyles from '@mui/styles/createStyles';
//import makeStyles from '@mui/styles/makeStyles';
//import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WoodpeckerViewModel from './WoodpeckerViewModel';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//            marginBottom: theme.spacing(2),
//            minHeight: '300px',
//            display: "flex",
//            flexDirection: "column",
//            justifyContent: "center"
//        },
//        notes: {
//            whiteSpace: 'pre-wrap'
//        }
//    })
//);

interface IStatsProps {
    woodpeckerDetails: WoodpeckerViewModel;
}

export default function Stats(props: IStatsProps) {
    // const classes = useStyles();
    // const { user, clientNote, clientId } = props;


    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="body1">Prospects: {props.woodpeckerDetails.stats.prospects}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">Delivered: {props.woodpeckerDetails.stats.delivery}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">Opened: {Math.round(props.woodpeckerDetails.stats.opened * 1000 / props.woodpeckerDetails.stats.delivery) / 10}%</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" textAlign="center">Interest Level</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} />
                        <Grid item xs={2}>
                            <SentimentSatisfiedAltIcon fontSize="medium" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" >{props.woodpeckerDetails.stats.interested}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} />
                        <Grid item xs={2}>
                            <SentimentNeutralIcon fontSize="medium" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{props.woodpeckerDetails.stats.maybe_later}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} />
                        <Grid item xs={2}>
                            <SentimentVeryDissatisfiedIcon fontSize="medium" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{props.woodpeckerDetails.stats.not_interested}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}