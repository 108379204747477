import * as React from 'react';
import EmailReportTable from './EmailReportTable';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function EmailReportContainer() {
    const [tab, setTab] = React.useState(2);

    const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
        setTab(newTab);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Weekly" id={`tab-0`} aria-controls={`tabpanel-0`} />
                    <Tab label="Monthly" id={`tab-1`} aria-controls={`tabpanel-1`} />
                    <Tab label="Custom" id={`tab-2`} aria-controls={`tabpanel-2`} />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <EmailReportTable tab={tab} />
            </Grid>
        </Grid>
    );
}