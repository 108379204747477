import { CampaignListViewModel } from "./CampaignViewModel";


export default class ClientViewModel {
    public id: number = 0;
    public clientName: string = '';
    public partner: string = '';
    public contactName: string = '';
    public contactEmail: string = '';
    public contactPhone: string = '';
    public startDate: Date = new Date();
    public clientType: string = '';
    public teamLeader: string = '';
    public teamLeaderId: string = '';
    public campaigns: CampaignListViewModel[] = [];
    public weeklyTargetOpportunities: number = 0;
    public countryId: number = 0;
    public country: string = '';
    public packageId: number = 0;
    public packageName: string = '';
    public price: number = 0;
    public status: string = '';
    public agreementLength: string = '';
    public renewalDate: number = 0;
    public agreementEnd: Date | null = null;
}

export class CreateClientViewModel {
    public clientName: string = '';
    public contactName: string = '';
    public contactEmail: string = '';
    public contactPhone: string = '';
    public startDate: Date = new Date();
    public ref_PackageId: number = 0;
    public teamLeader: string = '';
    public ref_CountryId: number = 0;
}

export class UserClientListViewModel {
    public name: string = '';
    public clients: ClientViewModel[] = [];
}