import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ClientViewModel from '../ViewModels/ClientViewModel';
import ClientTableRow from './ClientTableRow';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import authService from '../api-authorization/AuthorizeService';

interface IClientTableProps {
    clients: ClientViewModel[];
    roles: string | string[];
    refresh: () => void;
}

export default function ClientTable(props: IClientTableProps) {
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => { getData(); }, []);

    const items = props.clients.map((item, index) =>
        <ClientTableRow key={index} teamLeaders={users} client={item} roles={props.roles} refresh={props.refresh} />
    );

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }

    return (
        <Table aria-label="client list">
            <TableHead>
                <TableRow>
                    <TableCell>Client</TableCell>
                    <TableCell>Client Type</TableCell>
                    <TableCell>Onboarded Date</TableCell>
                    <TableCell>Account Manager</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell />
                    {
                        props.roles !== "Executive" &&
                        <React.Fragment>
                            <TableCell />
                        </React.Fragment>
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {items}
            </TableBody>
        </Table>
    );
}