import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MultiFileInput from './MultiFileInput';
import FileDataViewModel from '../ViewModels/FileDataViewModel';
import { UtilityMethods } from '../Clients/ClientPage/UtilityMethods';
import { CreateFileViewModel } from '../ViewModels/CreateFileViewModel';
//const useStyles = makeStyles((theme: Theme) =>
//    createStyles({
//        paper: {
//            padding: theme.spacing(2),
//        },
//        table: {
//            minWidth: 650,
//        },
//    })
//);

export default function Profile() {
    // const classes = useStyles();
    const [openUploadDialog, setUploadOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [fileData, setFileData] = React.useState<any>(null);
    const [files, setFiles] = React.useState<any>(null);
    const [submitting, setSubmitting] = React.useState(false);
    const onClose = (refresh: boolean) => {
        if (!submitting) {
            //setExec({ ClientId: ClientId, userId: '', startDate: new Date(), endDate: null });
            setUploadOpen(false);
            if (refresh) {
            }
        }
    }
    const openUploaderDialog = () => {
        setUploadOpen(true);
    }
    const removeFile = (fileData2: FileDataViewModel,) => {
        let f = files;
        let filesData = fileData;
        const dataindex = filesData.indexOf(fileData2);
        if (dataindex > -1) {
            filesData.splice(dataindex, 1);
            f.splice(dataindex, 1);
        }
        setFiles(f);
        setFileData(filesData);
    }
    const submit = (fileData: FileDataViewModel[], file: File[]) => {
        setSubmitting(true);
        setError('');
        let formdata: FormData = new FormData();
        var files: CreateFileViewModel[] = [];
        for (var i = 0; i < file.length; i++) {
            let createFileViewModel = new CreateFileViewModel;
            createFileViewModel.file = file[i];
            files.push(createFileViewModel);
        }
        formdata = UtilityMethods.convertModelToFormData(files, formdata);
        fetch('User/UploadAvatar', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            body: formdata
        })
            .then((response: Response) => response.json())
            .then((data: any) => {
                setSubmitting(false);
                setUploadOpen(false);
                var source = '/User/GetAvatar',
                    timestamp = (new Date()).getTime(),
                    newUrl = source + '?_=' + timestamp;
                var img = document.getElementById("avatar") as HTMLImageElement;
                img!.src = newUrl;
            })
            .catch(error => {
                setSubmitting(false);
                setError('An error occured');
            });
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Avatar
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img id="avatar" src="/User/GetAvatar" width="100%"></img>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="secondary" variant="outlined" fullWidth size="small" onClick={openUploaderDialog}>Upload Avatar</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={openUploadDialog} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Upload Files</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MultiFileInput
                                fileData={fileData}
                                files={files}
                                addFile={submit}
                                removeFile={removeFile}
                                submitting={submitting}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose(false)} fullWidth color="secondary" disabled={submitting}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}