import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { OpportunityViewModel } from '../../ViewModels/CampaignViewModel';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        wordBreak: {
            wordBreak: 'break-all'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface IListItemProps {
    lead: OpportunityViewModel;
    refresh: () => void;
}

export default function LeadListItem(props: IListItemProps) {
    const classes = useStyles();
    const { lead } = props;
    

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={lead.id + "-content"}
                id={lead.id + "-header"}
            >
                <Typography className={classes.heading}>{lead.name}</Typography>
                {
                    lead.interested =="INTERESTED"?
                        <SentimentSatisfiedAltIcon fontSize="medium" />
                        :
                        <SentimentNeutralIcon fontSize="medium" />
                }
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="caption">{new Date(lead.timestamp).toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Company</Typography>
                        <Typography>{lead.companyName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Link</Typography>
                        <Typography className={classes.wordBreak}>{lead.link}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Phone Number</Typography>
                        <Typography className={classes.wordBreak}>{lead.phoneNumber}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Email</Typography>
                        <Typography className={classes.wordBreak}>{lead.email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Notes</Typography>
                        <Typography>{lead.notes}</Typography>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}