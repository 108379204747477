import * as React from 'react';
//import Paper from '@mui/material/Paper';
//import { createStyles, Theme, makeStyles } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ClientViewModel/*, { UserClientListViewModel }*/ from '../ViewModels/ClientViewModel';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import ClientTable from './ClientTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 650,

        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        pagination: {
            paddingTop: theme.spacing(2),
        }
    }),

);

export default function ClientList() {
    const classes = useStyles();

    const [clients, setClients] = React.useState<ClientViewModel[]>([]);
    const [filteredClients, setFilteredClients] = React.useState<ClientViewModel[]>([]);
    //const [userClients, setUserClients] = React.useState<UserClientListViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(true);
    const [roles, setRoles] = React.useState<string[] | string>("");
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [pageMax, setPageMax] = React.useState(0);

    React.useEffect(() => {
        const initialise = async () => {
            setLoaded(false);
            const user = await authService.getUser();
            setRoles(user.role);
        }
        initialise();
    }, []);

    React.useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [clients, loaded]);
    React.useEffect(() => {
        const filter = () => {
            let filtered = [...clients];
            if (search.length > 0) {
                filtered = filtered.filter(item => item.clientName.toLowerCase().includes(search.toLowerCase()));
            }
            setPageMax(Math.ceil(filtered.length / 25));
            if (page > Math.ceil(filtered.length / 25)) {
                setPage(1);
            }
            filtered = filtered.splice((page - 1) * 25, 25);
            setFilteredClients(filtered);
        }
        filter();
    }, [search, clients, page]);
    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue);
    };
    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        if (roles === 'Executive') {
            fetch('Client/GetUserClientsList?userId=' + user.sub, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setClients(data);
                })
                .catch(error => { setLoaded(true) });
        } else {
            fetch('Client/GetClients', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => response.json())
                .then(data => {
                    setClients(data);
                    setLoaded(true);
                })
                .catch(error => { setLoaded(true) });
        }
    }, [roles]);
    React.useEffect(() => {
        if (roles && roles.length > 0) {
            getData();
        }
    }, [roles, getData]);

    const createButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: 'Clients/Create'
            }}
            {...props} />
    ));

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    //const filter = React.useCallback(() => {
    //    let filtered = clients;

    //    if (search.length > 0) {
    //        filtered = filtered.filter(item => item.clientName.toLowerCase().includes(search.toLowerCase()));
    //    }

    //    setFilteredClients(filtered);
    //}, [search, clients]);
    //React.useEffect(() => {
    //    filter();
    //}, [search, filter]);

    const list = <ClientTable clients={filteredClients} roles={roles} refresh={getData} />;

    return (
        //<Paper className={classes.paper}>
        //</Paper>
        <Grid container>
            <Grid item xs={12} style={{ marginTop: "5px" }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h2">Clients</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={search}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onFilterChange}
                                />
                            </Grid>
                            {(roles && (roles.includes('SuperAdmin') ||roles.includes('Admin') || roles.includes('Team Leader') || roles.includes('Creator'))) &&
                                <Grid item>
                                    <Button fullWidth variant="contained" color="secondary" component={createButton} disabled={!loaded}>Create Client</Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {!loaded && <LinearProgress />}
                {list}
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center" className={classes.pagination}>
                    <Grid item>
                        <Pagination page={page} onChange={handlePageChange} count={pageMax} showFirstButton showLastButton />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}